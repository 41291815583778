import { getAccessToken } from '@vs/oidc-client';

import { oidcConfig } from '../constants/oidc';

export default function getUserAccessToken() {
  return getAccessToken({
    authority: oidcConfig.authority,
    client_id: oidcConfig.client_id,
  });
}
