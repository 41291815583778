import i18n, { i18n as I18n } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import { langsMapping } from './hooks';

export type OptsInitI18n = {
  /**
   * default name space, this should match the folder name of app's i18n term files in root public folder
   * @default 'common'
   */
  defaultNS?: string;
  /**
   * path of app's i18n term files folder on server, normally it should be `${base path}/locales`
   */
  localesFolderPath: string;
};
/**
 * create i18n instance
 *
 * @export
 * @param {OptsInitI18n} options
 * @return {I18n}
 */
export function createI18n(options: OptsInitI18n): I18n {
  const { defaultNS = 'common', localesFolderPath } = options;
  const instance = i18n
    .use(LanguageDetector)
    .use(Backend)
    .use(initReactI18next);

  instance.init({
    backend: {
      loadPath: () => `${localesFolderPath}/{{ns}}/{{lng}}.json`,
    },
    detection: {
      convertDetectedLanguage: lang => langsMapping(lang),
    },
    fallbackLng: 'en',
    // debug: true,
    ns: [defaultNS, 'shared'],
    defaultNS,
    keySeparator: '.',
    interpolation: {
      escapeValue: false,
    },
    lowerCaseLng: true,
    react: {
      useSuspense: false,
    },
  });

  instance.on('languageChanged', locale => {
    const direction = i18n.dir(locale);
    document.dir = direction;
  });

  if (import.meta.hot) {
    import.meta.hot.on('locales-update', () => {
      instance.reloadResources().then(() => {
        instance.changeLanguage(langsMapping(instance.language));
      });
    });
  }

  return instance;
}
