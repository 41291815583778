import { SigninRedirectArgs } from 'oidc-client-ts';
import { useCallback, useEffect, useRef } from 'react';
import { useAuth } from 'react-oidc-context';

import { getIsSessionEndInSameClient } from '../_private';
import {
  registerOnLockEventListener,
  releaseLockForTokenRefresh,
  removeOnLockEventListener,
} from '../utils';

export type ArgsUseAutoSilentRefreshToken = {
  signinRedirectArgs?: SigninRedirectArgs;
  onTokenExpired?: (
    error: unknown,
    reason: 'same_client_session_end' | 'session_end'
  ) => void;
  onTokenRefreshStart?: () => void;
  onTokenRefreshEnd?: () => void;
  skip?: boolean;
};

export function useAutoSilentRefreshToken({
  onTokenRefreshStart = () => {},
  onTokenRefreshEnd = () => {},
  onTokenExpired = () => {},
  signinRedirectArgs,
  skip = false,
}: ArgsUseAutoSilentRefreshToken = {}) {
  const stateRef = useRef<{
    signinRedirectArgs: ArgsUseAutoSilentRefreshToken['signinRedirectArgs'];
  }>({ signinRedirectArgs });
  stateRef.current.signinRedirectArgs = signinRedirectArgs;
  const cbRef = useRef<
    Required<
      Pick<
        ArgsUseAutoSilentRefreshToken,
        'onTokenExpired' | 'onTokenRefreshEnd' | 'onTokenRefreshStart'
      >
    >
  >({
    onTokenExpired: () => {},
    onTokenRefreshEnd: () => {},
    onTokenRefreshStart: () => {},
  });
  cbRef.current.onTokenExpired = onTokenExpired;
  cbRef.current.onTokenRefreshStart = onTokenRefreshStart;
  cbRef.current.onTokenRefreshEnd = onTokenRefreshEnd;

  const { isAuthenticated, events, signinSilent, settings } = useAuth();

  const silentSignin = useCallback(async () => {
    try {
      const user = await signinSilent(stateRef.current.signinRedirectArgs);
      if (!user) {
        throw new Error('User not found.');
      }
    } catch (error) {
      cbRef.current.onTokenExpired(error, 'session_end');
    }
  }, [signinSilent]);

  useEffect(() => {
    if (!skip && isAuthenticated) {
      let isTokenExpiredWhileDocNotViewing = false;

      const onVisibilityChange = async () => {
        if (document.visibilityState === 'visible') {
          cbRef.current.onTokenRefreshStart();
          if (getIsSessionEndInSameClient()) {
            cbRef.current.onTokenExpired(null, 'same_client_session_end');
          } else {
            if (isTokenExpiredWhileDocNotViewing) {
              await silentSignin();
              isTokenExpiredWhileDocNotViewing = false;
            }
          }
          cbRef.current.onTokenRefreshEnd();
        }
      };

      const onLockForTokenRefresh = async () => {
        cbRef.current.onTokenRefreshStart();
        await silentSignin();
        releaseLockForTokenRefresh();
        cbRef.current.onTokenRefreshEnd();
      };

      registerOnLockEventListener(onLockForTokenRefresh);

      window.addEventListener('visibilitychange', onVisibilityChange);

      const removeAccessTokenExpiringListener = events.addAccessTokenExpiring(
        () => {
          if (document.visibilityState === 'hidden') {
            isTokenExpiredWhileDocNotViewing = true;
            return;
          }
          silentSignin();
        }
      );

      return () => {
        window.removeEventListener('visibilitychange', onVisibilityChange);
        removeOnLockEventListener(onLockForTokenRefresh);
        removeAccessTokenExpiringListener();
      };
    }
  }, [silentSignin, skip, isAuthenticated, events, settings, onTokenExpired]);
}
