import { BaseGlobalState } from '@vs/rtk/slices/genericGlobal';
import { PortalBackdrop } from '@vs/ui/portal/backdrop';
import { memo } from 'react';
import { TypedUseSelectorHook, useSelector } from 'react-redux';

const useStateSelector: TypedUseSelectorHook<{ general: BaseGlobalState }> =
  useSelector;

/**
 * Show global loading mask according to redux state.
 * * Redux store need to include `generalStateSlice`
 *
 * @export
 * @returns {ReactElement}
 */
export const PortalAppLoading = memo(() => {
  const isLoadingTarget = useStateSelector(s => s.general.isLoadingTarget);
  const showLoading = Object.keys(isLoadingTarget).length > 0;

  return <PortalBackdrop type="loading" open={showLoading} />;
});

export default PortalAppLoading;
