import {
  defaultAuthRedirectPath,
  defaultPostLogoutRedirectPath,
} from '@vs/oidc-client';
import { OidcClientSettings } from 'oidc-client-ts';

import { basePath } from '.';
import { env } from './env';

export const oidcConfig: OidcClientSettings = {
  post_logout_redirect_uri: `${window.location.origin}${basePath}${defaultPostLogoutRedirectPath}`,
  redirect_uri: `${window.location.origin}${basePath}${defaultAuthRedirectPath}`,
  response_type: 'code',
  scope: 'openid profile email',
  client_id: 'b78ecdf9-b9fd-4027-b4f0-2bd8b292e6ed',
  authority: env.oidcAuthority.vs,
};
