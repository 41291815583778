import { useAccountInfo } from '@vs/hooks/hook-account-info';
import { LocalStorageKeys } from '@vs/types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const langsMap: Record<string, string> = {
  zh: 'zh-TW',
};
export function langsMapping(langCode: string) {
  return (langsMap[langCode] ?? langCode).toLocaleLowerCase();
}

type Options = {
  /**
   * If true, use `lang_code` of api data as user lang; if false, use navigator.language as user lang
   * @default false
   */
  skipAccountLang?: boolean;
};
type ReturnUseHandleI18nSwitch = {
  /**
   * true if hooks is first call and initializing (api data fetching)
   */
  isInitializing: boolean;
};
const defaultOptions = {
  skipAccountLang: false,
};
/**
 * trigger i18n changeLanguage when app initialize and user lang changed
 *
 * @export
 * @param {Options} [options=defaultOptions]
 * @return {ReturnUseHandleI18nSwitch}
 */
export function useHandleI18nSwitch(
  options: Options = defaultOptions
): ReturnUseHandleI18nSwitch {
  const { skipAccountLang = false } = options;
  const { i18n } = useTranslation();
  const { isLoading, data } = useAccountInfo({ skip: skipAccountLang });
  const userLang = data?.lang_code;
  const [isInited, setInited] = useState(false);

  useEffect(() => {
    if (!isLoading) {
      const lang =
        userLang ??
        localStorage.getItem(LocalStorageKeys.VS_PORTAL_LOCALE) ??
        navigator.language.split('-')[0];
      const mappedLang = langsMapping(lang);
      i18n.changeLanguage(mappedLang, () => {
        localStorage.setItem(LocalStorageKeys.VS_PORTAL_LOCALE, lang);
        setInited(true);
      });
    }
  }, [userLang, i18n, isLoading]);

  return {
    isInitializing: isLoading && !isInited,
  };
}
