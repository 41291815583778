import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { PortalToastProps } from '@vs/ui/portal/toast';
import type { SetRequired } from 'type-fest';

export type ToastSettings = {
  message: string;
  duration: number;
  vertical: PortalToastProps['vertical'];
  horizontal: PortalToastProps['horizontal'];
  actionType: PortalToastProps['actionType'];
};

export type BaseGlobalState = {
  openDrawer: boolean;
  isLoadingTarget: Record<string, boolean>;
  isToast: boolean;
  toastSetting: ToastSettings;
};

const initialToastSettings: ToastSettings = {
  message: '',
  duration: 5000,
  vertical: 'bottom',
  horizontal: 'center',
  actionType: null,
};

const initialBaseState: BaseGlobalState = {
  openDrawer: false,
  isLoadingTarget: {},
  isToast: false,
  toastSetting: initialToastSettings,
};

export const generalStateSlice = createSlice({
  name: 'general',
  initialState: initialBaseState,
  reducers: {
    setOpenDrawer: (state, action: PayloadAction<boolean>) => {
      state.openDrawer = action.payload;
    },
    setLoadingTarget: (
      state,
      action: PayloadAction<{ targetId: string; status: boolean }>
    ) => {
      const { status, targetId } = action.payload;
      if (status) {
        state.isLoadingTarget[targetId] = true;
      } else {
        delete state.isLoadingTarget[targetId];
      }
    },
    showToast: (
      state,
      action: PayloadAction<SetRequired<Partial<ToastSettings>, 'message'>>
    ) => {
      state.isToast = true;
      state.toastSetting = { ...state.toastSetting, ...action.payload };
    },
    hideToast: state => {
      state.isToast = false;
    },
  },
});

export const { setOpenDrawer, showToast, hideToast, setLoadingTarget } =
  generalStateSlice.actions;
