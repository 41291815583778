import { createContext } from 'react';

import { Handlers, TableActionState } from './type';

export const defaultTableActionState: TableActionState = {
  selectedItem: null,
  selectedItems: [],
  sortOrder: null,
  sortField: undefined,
  pageSize: null,
  curPage: null,
  search: null,
};

export const context = createContext<Handlers>({
  getTableActionState: () => defaultTableActionState,
  updateTableActionState: () => {},
});
