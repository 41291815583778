import { Popover } from '@mui/material';
import {
  AppsIcon,
  ClassSwiftIcon,
  IconComponent,
  ManagerIcon,
  MyViewboardIcon,
  TeamOneIcon,
} from '@vs/ui/vs-icons';
import classNames from 'classnames';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';

type PropsPortalAppsPanel = {
  className?: string;
};

export const PortalAppsPanel = memo(({ className }: PropsPortalAppsPanel) => {
  const { t } = useTranslation(['shared']);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  return (
    <>
      <div
        className={classNames(
          'p-2 cursor-pointer transition-colors hover:bg-neutral-300 rounded',
          className
        )}
        onClick={e => setAnchorEl(e.currentTarget)}
      >
        <AppsIcon className="text-lg" />
      </div>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        slotProps={{
          paper: {
            className:
              'w-80 px-6 py-3 flex flex-col rounded-lg !shadow-md border border-vs-neut-200',
          },
        }}
      >
        <div className="vs-body-xs-regular text-vs-base-content-variant mb-1">
          {t('apps_panel.title')}
        </div>
        <div className="max-h-[354px] flex items-center flex-wrap">
          {dataList
            .filter(d => !d.hide)
            .map(d => (
              <AppButton
                key={d.i18nKey}
                i18nKey={d.i18nKey}
                Icon={d.Icon}
                href={d.href}
              />
            ))}
        </div>
      </Popover>
    </>
  );
});

type AppData = { i18nKey: string; Icon: IconComponent; href?: string };
type AppDataMeta = { hide?: boolean; order?: number };
type DataList = (AppData & AppDataMeta)[];

const dataList: DataList = [
  {
    i18nKey: 'apps_panel.app.myviewboard',
    Icon: MyViewboardIcon,
    href: 'https://myviewboard.com/',
    hide: true,
  },
  { i18nKey: 'apps_panel.app.teamone', Icon: TeamOneIcon, hide: true },
  { i18nKey: 'apps_panel.app.manager', Icon: ManagerIcon, hide: true },
  {
    i18nKey: 'apps_panel.app.classswift',
    Icon: ClassSwiftIcon,
    href: 'https://www.classswift.viewsonic.io/',
  },
];

function AppButton({ i18nKey, Icon, href }: AppData) {
  const { t } = useTranslation(['shared']);
  const content = (
    <div
      className="aspect-square rounded-lg cursor-pointer hover:bg-vs-base-300 transition-colors py-2 flex flex-col items-center justify-between"
      {...(href ? {} : { style: { flex: '0 1 33.33%' } })}
    >
      <div className="p-2 aspect-square flex items-start justify-center mb-1 bg-white rounded-lg">
        <Icon className="text-[40px]" />
      </div>
      <div className="vs-label-xs-regular text-vs-base-content">
        {t(i18nKey)}
      </div>
    </div>
  );
  return href ? (
    <a
      href={href}
      target="_blank"
      rel="noreferrer"
      style={{ flex: '0 1 33.33%' }}
    >
      {content}
    </a>
  ) : (
    content
  );
}
