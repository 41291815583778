import { tabSessionUUID } from '@vs/utils/constants';
import { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';

import { useOidcConfig, useSignOut } from '.';
import { setIsSessionEndInSameClient } from '../_private';

export enum BroadcastIds {
  CHANNEL = 'vs-channel-oidc-client',
  MESSAGE = 'vs-message-oidc-client',
}

export type MessagePayload = {
  id: BroadcastIds.MESSAGE;
  payload: {
    sessionUUID: string;
    client_id: string;
    authority: string;
    isLogout: boolean;
  };
};

function isAutoLogoutMsg(msg: unknown): msg is MessagePayload {
  return (
    msg !== null &&
    typeof msg === 'object' &&
    'id' in msg &&
    msg.id === BroadcastIds.MESSAGE &&
    'payload' in msg &&
    msg.payload !== null &&
    typeof msg.payload === 'object' &&
    'client_id' in msg.payload &&
    'authority' in msg.payload &&
    'sessionUUID' in msg.payload
  );
}
export function useHandleSameClientLogout() {
  const { isAuthenticated } = useAuth();
  const { client_id, authority } = useOidcConfig();
  const signOut = useSignOut();

  useEffect(() => {
    if (isAuthenticated) {
      setSameClientLogout({
        sessionUUID: tabSessionUUID,
        authority,
        client_id,
        isLogout: false,
      });
    }
  }, [isAuthenticated, authority, client_id]);
  useEffect(() => {
    const channel = new BroadcastChannel(BroadcastIds.CHANNEL);

    const onMessage = (e: MessageEvent<MessagePayload>) => {
      const msg = e.data;
      if (isAutoLogoutMsg(msg)) {
        const p = msg.payload;
        if (
          p.sessionUUID !== tabSessionUUID &&
          p.authority === authority &&
          p.client_id === client_id
        ) {
          setIsSessionEndInSameClient(p.isLogout ?? false);
        }
      }
    };

    channel.addEventListener('message', onMessage);

    return () => {
      channel.removeEventListener('message', onMessage);
      channel.close();
    };
  }, [signOut, client_id, authority]);
}

export function setSameClientLogout(payload: MessagePayload['payload']) {
  const channel = new BroadcastChannel(BroadcastIds.CHANNEL);
  channel.postMessage({
    id: BroadcastIds.MESSAGE,
    payload,
  } as MessagePayload);

  channel.close();
}
