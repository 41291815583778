import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { ReactNode } from 'react';

export interface PortalBackButtonProps {
  children: ReactNode;
  onClick: () => void;
}

export function PortalBackButton({ children, onClick }: PortalBackButtonProps) {
  return (
    <div
      onClick={onClick}
      className="flex h-6 cursor-pointer items-center justify-end mb-4 md:mb-0"
    >
      <KeyboardBackspaceIcon className="me-1 text-lg" />
      <span className="vs-label-sm-medium text-vs-base-content hidden font-medium lg:block">
        {children}
      </span>
    </div>
  );
}

export default PortalBackButton;
