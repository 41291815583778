import { PortalAvatarMenu } from '@vs/components/portal-avatar-menu';
import { PortalHeaderWithPathMeta } from '@vs/components/portal-header-with-path-meta';
import { setOpenDrawer } from '@vs/rtk/slices/genericGlobal';
import { PortalAppsPanel } from '@vs/ui/portal/apps-panel';
import { PortalHeaderAppSubTitle } from '@vs/ui/portal/header';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { env } from '../../constants/env';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';

function AppHeader() {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const openDrawer = useAppSelector(s => s.general.openDrawer);

  return (
    <PortalHeaderWithPathMeta
      onLogoClick={() => navigate({ ...location, pathname: '/' })}
      subLogo={
        <PortalHeaderAppSubTitle>
          {t('shared:header.app_subtitle.account')}
        </PortalHeaderAppSubTitle>
      }
      shouldElevateOnScroll
      shouldFixed
      onBurgerIconClick={() => dispatch(setOpenDrawer(!openDrawer))}
      othersCTA={<PortalAppsPanel className="me-4" />}
      avatarMenu={
        <PortalAvatarMenu showEntityInfo showEntitySignupEntry env={env} />
      }
    />
  );
}

export default memo(AppHeader);
