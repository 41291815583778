import { IdTokenClaims } from 'oidc-client-ts';
import { useAuth } from 'react-oidc-context';

export type OidcUserProfile = IdTokenClaims & {
  sub: string;
  name: string;
  family_name: string;
  given_name: string;
  picture: string;
  locale: string;
  lang_code: string;
  occupation: string | null;
  shard_region: string;
  updated_at: number;
  email: string;
};

export function useOidcUserProfile() {
  const auth = useAuth();
  const profile = auth?.user?.profile;

  return profile as OidcUserProfile | undefined;
}
