import { Env } from './env.type';

const vsAdminDomain = 'https://admin.stage.viewsonic.cloud';
const vsAppDomain = 'https://stage.cloud.viewsonic.com';
export const env: Env = {
  apiEndpoint: {
    mvb: 'https://stageapi.myviewboard.com',
    vs: 'https://api.stage.viewsonic.cloud',
    og: 'https://originals-api.stage.myviewboard.com/admin/graphql',
    vsSubscription:
      'https://api.stage.viewsonic.cloud/subscription/graphql/v1/subscription',
    vsAuth: vsAppDomain,
    vsAdminAuth: vsAdminDomain,
  },
  oidcAuthority: {
    vs: `${vsAppDomain}/auth/v1/oidc`,
    vsAdmin: `${vsAdminDomain}/auth/v1/oidc`,
    mvb: `https://api.stage.myviewboard.com/oidc/provider`,
    newMvb: 'https://auth.dev.myviewboard.com/oidc/v1',
  },
  appUrl: {
    legalDoc: `${vsAppDomain}/legal`,
    accountPortal: `${vsAppDomain}/account`,
    entityPortal: `${vsAppDomain}/entity`,
  },
  applicationInsightsInstrumentationKey: {
    mvb: '216b8495-a1e5-41b7-9e28-954ec64abaf4',
  },
  assets: {
    vs: 'https://assets.stage.viewsonic.cloud',
  },
};
