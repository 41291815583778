import { vsCommonApiBase as api } from './vsCommonApiBase';

const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    rootControllerGetHealth: build.query<
      RootControllerGetHealthApiResponse,
      RootControllerGetHealthApiArg
    >({
      query: () => ({ url: `/health` }),
    }),
    rootControllerGetOpenHealth: build.query<
      RootControllerGetOpenHealthApiResponse,
      RootControllerGetOpenHealthApiArg
    >({
      query: () => ({ url: `/common/health` }),
    }),
    geolocationControllerGetCountries: build.query<
      GeolocationControllerGetCountriesApiResponse,
      GeolocationControllerGetCountriesApiArg
    >({
      query: () => ({ url: `/common/api/v1/countries` }),
    }),
    geolocationControllerGetCountry: build.query<
      GeolocationControllerGetCountryApiResponse,
      GeolocationControllerGetCountryApiArg
    >({
      query: queryArg => ({ url: `/common/api/v1/countries/${queryArg.ciso}` }),
    }),
    geolocationControllerGetCountriesStates: build.query<
      GeolocationControllerGetCountriesStatesApiResponse,
      GeolocationControllerGetCountriesStatesApiArg
    >({
      query: queryArg => ({
        url: `/common/api/v1/countries/${queryArg.ciso}/states`,
      }),
    }),
    geolocationControllerGetCities: build.query<
      GeolocationControllerGetCitiesApiResponse,
      GeolocationControllerGetCitiesApiArg
    >({
      query: queryArg => ({
        url: `/common/api/v1/countries/${queryArg.ciso}/cities`,
      }),
    }),
    geolocationControllerGetStatesCities: build.query<
      GeolocationControllerGetStatesCitiesApiResponse,
      GeolocationControllerGetStatesCitiesApiArg
    >({
      query: queryArg => ({
        url: `/common/api/v1/countries/${queryArg.ciso}/states/${queryArg.siso}/cities`,
      }),
    }),
    geolocationControllerGetStates: build.query<
      GeolocationControllerGetStatesApiResponse,
      GeolocationControllerGetStatesApiArg
    >({
      query: () => ({ url: `/common/api/v1/states` }),
    }),
    geolocationControllerGetState: build.query<
      GeolocationControllerGetStateApiResponse,
      GeolocationControllerGetStateApiArg
    >({
      query: queryArg => ({
        url: `/common/api/v1/countries/${queryArg.ciso}/states/${queryArg.siso}`,
      }),
    }),
    languageControllerGetLanguages: build.query<
      LanguageControllerGetLanguagesApiResponse,
      LanguageControllerGetLanguagesApiArg
    >({
      query: () => ({ url: `/common/api/v1/languages` }),
    }),
    managementControllerGetLanguages: build.query<
      ManagementControllerGetLanguagesApiResponse,
      ManagementControllerGetLanguagesApiArg
    >({
      query: () => ({ url: `/common/api/v1/management/languages` }),
    }),
    managementControllerCreateLanguages: build.mutation<
      ManagementControllerCreateLanguagesApiResponse,
      ManagementControllerCreateLanguagesApiArg
    >({
      query: queryArg => ({
        url: `/common/api/v1/management/languages`,
        method: 'POST',
        body: queryArg.createLanguageDto,
      }),
    }),
    managementControllerUpdateLanguages: build.mutation<
      ManagementControllerUpdateLanguagesApiResponse,
      ManagementControllerUpdateLanguagesApiArg
    >({
      query: queryArg => ({
        url: `/common/api/v1/management/languages/${queryArg.langCode}`,
        method: 'PATCH',
        body: queryArg.updateLanguageDto,
      }),
    }),
    managementControllerDeleteLanguages: build.mutation<
      ManagementControllerDeleteLanguagesApiResponse,
      ManagementControllerDeleteLanguagesApiArg
    >({
      query: queryArg => ({
        url: `/common/api/v1/management/languages/${queryArg.langCode}`,
        method: 'DELETE',
      }),
    }),
    managementControllerGetVsArea: build.query<
      ManagementControllerGetVsAreaApiResponse,
      ManagementControllerGetVsAreaApiArg
    >({
      query: () => ({ url: `/common/api/v1/management/countries/vs-area` }),
    }),
    managementControllerUpdateCountry: build.mutation<
      ManagementControllerUpdateCountryApiResponse,
      ManagementControllerUpdateCountryApiArg
    >({
      query: queryArg => ({
        url: `/common/api/v1/management/countries/${queryArg.ciso}/vs-area`,
        method: 'PATCH',
        body: queryArg.updateCountry2AreaDto,
      }),
    }),
    managementControllerGetShardRegion: build.query<
      ManagementControllerGetShardRegionApiResponse,
      ManagementControllerGetShardRegionApiArg
    >({
      query: () => ({
        url: `/common/api/v1/management/countries/shard_region`,
      }),
    }),
    managementControllerUpdateCountryShardRegion: build.mutation<
      ManagementControllerUpdateCountryShardRegionApiResponse,
      ManagementControllerUpdateCountryShardRegionApiArg
    >({
      query: queryArg => ({
        url: `/common/api/v1/management/countries/${queryArg.ciso}/shard_region`,
        method: 'PATCH',
        body: queryArg.updateCountryShardRegionDto,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as vsCommonApiInjected };
export type RootControllerGetHealthApiResponse = unknown;
export type RootControllerGetHealthApiArg = void;
export type RootControllerGetOpenHealthApiResponse = unknown;
export type RootControllerGetOpenHealthApiArg = void;
export type GeolocationControllerGetCountriesApiResponse =
  /** status 200  */ VsCountries[];
export type GeolocationControllerGetCountriesApiArg = void;
export type GeolocationControllerGetCountryApiResponse =
  /** status 200  */ Country;
export type GeolocationControllerGetCountryApiArg = {
  /** ISO2 Code of Country */
  ciso: string;
};
export type GeolocationControllerGetCountriesStatesApiResponse =
  /** status 200  */ States[];
export type GeolocationControllerGetCountriesStatesApiArg = {
  /** ISO2 Code of Country */
  ciso: string;
};
export type GeolocationControllerGetCitiesApiResponse =
  /** status 200  */ Cities[];
export type GeolocationControllerGetCitiesApiArg = {
  /** ISO2 Code of Country */
  ciso: string;
};
export type GeolocationControllerGetStatesCitiesApiResponse =
  /** status 200  */ Cities[];
export type GeolocationControllerGetStatesCitiesApiArg = {
  /** ISO2 Code of State */
  siso: string;
  /** ISO2 Code of Country */
  ciso: string;
};
export type GeolocationControllerGetStatesApiResponse =
  /** status 200  */ State[];
export type GeolocationControllerGetStatesApiArg = void;
export type GeolocationControllerGetStateApiResponse = /** status 200  */ State;
export type GeolocationControllerGetStateApiArg = {
  /** ISO2 Code of State */
  siso: string;
  /** ISO2 Code of Country */
  ciso: string;
};
export type LanguageControllerGetLanguagesApiResponse =
  /** status 200  */ Language[];
export type LanguageControllerGetLanguagesApiArg = void;
export type ManagementControllerGetLanguagesApiResponse =
  /** status 200  */ string[];
export type ManagementControllerGetLanguagesApiArg = void;
export type ManagementControllerCreateLanguagesApiResponse =
  /** status 200  */ Language;
export type ManagementControllerCreateLanguagesApiArg = {
  createLanguageDto: CreateLanguageDto;
};
export type ManagementControllerUpdateLanguagesApiResponse =
  /** status 200  */ void;
export type ManagementControllerUpdateLanguagesApiArg = {
  langCode: string;
  updateLanguageDto: UpdateLanguageDto;
};
export type ManagementControllerDeleteLanguagesApiResponse =
  /** status 200  */ void;
export type ManagementControllerDeleteLanguagesApiArg = {
  langCode: string;
};
export type ManagementControllerGetVsAreaApiResponse =
  /** status 200  */ string[];
export type ManagementControllerGetVsAreaApiArg = void;
export type ManagementControllerUpdateCountryApiResponse =
  /** status 200  */ void;
export type ManagementControllerUpdateCountryApiArg = {
  ciso: string;
  updateCountry2AreaDto: UpdateCountry2AreaDto;
};
export type ManagementControllerGetShardRegionApiResponse =
  /** status 200  */ string[];
export type ManagementControllerGetShardRegionApiArg = void;
export type ManagementControllerUpdateCountryShardRegionApiResponse =
  /** status 200  */ void;
export type ManagementControllerUpdateCountryShardRegionApiArg = {
  ciso: string;
  updateCountryShardRegionDto: UpdateCountryShardRegionDto;
};
export type VsCountries = {
  id?: number;
  name?: string;
  vs_area?: string;
  shard_region?: string;
  iso2?: string;
};
export type Country = {
  id?: number;
  name?: string;
  vs_area?: string;
  iso3?: string;
  iso2?: string;
  numeric_code?: string;
  phonecode?: string;
  capital?: string;
  currency?: string;
  currency_name?: string;
  currency_symbol?: string;
  tld?: string;
  native?: string;
  region?: string;
  subregion?: string;
  timezones?: string;
  translations?: string;
  latitude?: string;
  longitude?: string;
  emoji?: string;
  emojiU?: string;
};
export type Error = {
  code: string;
  path: string;
  request_id: string;
  status_code: number;
  service?: string;
  message: string;
  timestamp: string;
};
export type States = {
  id?: number;
  name?: string;
  iso2?: string;
};
export type Cities = {
  id?: number;
  name?: string;
};
export type State = {
  id?: number;
  name?: string;
  country_id?: number;
  country_code?: string;
  iso2?: string;
  type?: string;
  latitude?: string;
  longitude?: string;
};
export type Language = {
  code: string;
  name: string;
};
export type CreateLanguageDto = {
  code: string;
  name: string;
};
export type UpdateLanguageDto = {
  name: string;
};
export type UpdateCountry2AreaDto = {
  area?: string;
};
export type UpdateCountryShardRegionDto = {
  shard_region: string;
};
export const {
  useRootControllerGetHealthQuery,
  useRootControllerGetOpenHealthQuery,
  useGeolocationControllerGetCountriesQuery,
  useGeolocationControllerGetCountryQuery,
  useGeolocationControllerGetCountriesStatesQuery,
  useGeolocationControllerGetCitiesQuery,
  useGeolocationControllerGetStatesCitiesQuery,
  useGeolocationControllerGetStatesQuery,
  useGeolocationControllerGetStateQuery,
  useLanguageControllerGetLanguagesQuery,
  useManagementControllerGetLanguagesQuery,
  useManagementControllerCreateLanguagesMutation,
  useManagementControllerUpdateLanguagesMutation,
  useManagementControllerDeleteLanguagesMutation,
  useManagementControllerGetVsAreaQuery,
  useManagementControllerUpdateCountryMutation,
  useManagementControllerGetShardRegionQuery,
  useManagementControllerUpdateCountryShardRegionMutation,
} = injectedRtkApi;
