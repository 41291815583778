import { useOidcUserProfile } from '@vs/oidc-client';
import { useAccountApiControllerFindQuery } from '@vs/rtk/slices/apiVsAccount';

type Opts = {
  /** skip account api get request */
  skip?: boolean;
};

const defaultOpts = {
  skip: false,
};
/**
 * Use Viewsonic account api rtk response
 *
 * @export
 * @param {Opts} [opts=defaultOpts]
 * @returns {*}
 */
export function useAccountInfo(opts: Opts = defaultOpts) {
  const { skip = false } = opts;
  const oidcProfile = useOidcUserProfile();

  return useAccountApiControllerFindQuery(
    {
      accountId: oidcProfile?.sub ?? '',
    },
    { skip: !oidcProfile?.sub || skip }
  );
}

export default useAccountInfo;
