import { vsAuthApiBase as api } from './vsAuthApiBase';

const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    resetPassword: build.mutation<RespChangePassword, ArgsResetPassword>({
      query: ({ account, referer_uri }) => ({
        method: 'POST',
        url: `/auth/v1/api/account/send/reset-password`,
        body: {
          account,
          referer_uri,
        },
        responseHandler: resp => resp.text(),
      }),
    }),
    loginTargetByQrCode: build.mutation<void, ArgsloginTargetByQrCode>({
      query: ({ account_id, interaction_id }) => ({
        method: 'POST',
        url: '/auth/v1/api/account/qrcode',
        body: {
          account_id,
          interaction_id,
        },
      }),
    }),
    getCountry: build.query<{ viewerCountry: string | null }, void>({
      queryFn: async () => {
        const url = 'https://assets.dev.viewsonic.cloud/country.png';
        const response = await fetch(url, { method: 'GET', mode: 'cors' });
        const viewerCountry = response.headers.get('Viewer-Country');
        return { data: { viewerCountry: viewerCountry } };
      },
    }),
  }),
});
export { injectedRtkApi as vsAuthApiInjected };
export type ArgsResetPassword = {
  account: string;
  referer_uri: string;
};

export interface RespChangePassword {
  nativeCode: string;
  status: number;
  responseCode: number;
  messageCode: number;
  message: string;
}

export type ArgsloginTargetByQrCode = {
  account_id: string;
  interaction_id: string;
};
export const {
  useResetPasswordMutation,
  useLoginTargetByQrCodeMutation,
  useGetCountryQuery,
} = injectedRtkApi;
