import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { Divider } from '@mui/material';
import { PortalLayoutMode } from '@vs/types';
import classNames from 'classnames';
import { ReactNode, useEffect, useState } from 'react';

export type PortalHeaderProps = {
  onBurgerIconClick?: () => void;
  onLogoClick?: () => void;
  showMobileBurgerIcon?: boolean;
  shouldElevateOnScroll?: boolean;
  shouldFixed?: boolean;
  actions?: React.ReactNode;
  avatarMenu?: React.ReactNode;
  othersCTA?: React.ReactNode;
  subLogo?: React.ReactNode;
  mode?: PortalLayoutMode;
};

export function PortalHeader({
  onBurgerIconClick = () => {},
  onLogoClick = () => {},
  showMobileBurgerIcon = true,
  shouldElevateOnScroll = false,
  shouldFixed = false,
  actions = null,
  avatarMenu = null,
  othersCTA = null,
  subLogo = null,
  mode = 'app',
}: PortalHeaderProps) {
  const [hasScrolled, setHasScrolled] = useState(false);

  useEffect(() => {
    if (shouldElevateOnScroll) {
      const handleScroll = () => {
        setHasScrolled(window.scrollY > 0);
      };
      window.addEventListener('scroll', handleScroll, { passive: true });
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [shouldElevateOnScroll]);

  return (
    <div
      className={classNames(
        'z-40 flex h-14 items-center justify-between px-4 py-2 border-b transition-all md:h-16 md:py-3 lg:px-6 xl:px-8',
        { 'bg-vs-base-100 border-vs-neut-300': mode === 'app' },
        { 'bg-vs-base-300 border-b-transparent': mode === 'doc' },
        {
          shadow: shouldElevateOnScroll && hasScrolled,
          'fixed left-0 right-0 top-0 w-full': shouldFixed,
        }
      )}
    >
      <div className="flex items-center">
        {showMobileBurgerIcon && (
          <span
            className="hover:bg-vs-neut-200 me-2 cursor-pointer rounded p-2 transition-colors lg:hidden"
            onClick={onBurgerIconClick}
          >
            <MenuRoundedIcon className="text-vs-base-content h-[22px] w-[22px]" />
          </span>
        )}
        <div className="flex items-center cursor-pointer" onClick={onLogoClick}>
          <img
            src={`https://assets.dev.viewsonic.cloud/images/${
              subLogo ? 'vs-logo' : 'vs-logo-bird'
            }.png`}
            alt="Viewsonic"
            className="h-5 md:h-7"
          />
          {Boolean(subLogo) && (
            <div className="flex items-center">
              <Divider
                orientation="vertical"
                className="border-vs-neut-1000 mx-3 h-4 sm:h-5 sm:mx-4"
              />
              {subLogo}
            </div>
          )}
        </div>
      </div>
      {actions}
      <div className="flex items-center">
        {othersCTA}
        {avatarMenu}
      </div>
    </div>
  );
}

export default PortalHeader;

export function PortalHeaderAppSubTitle({ children }: { children: ReactNode }) {
  return (
    <span className="font-vs-portal text-xs md:text-xl text-vs-neut-1000">
      {children}
    </span>
  );
}
