import { VsSubscriptionGraphqlApiBase } from './vsSubscriptionApiBase';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  DateTime: { input: any; output: any };
};

/**  which payment source is allowed to pay for this product, e.g. apple, chargify, viewsonic, can be multiple values at same time. */
export enum AllowedPaymentSource {
  /** apple */
  Apple = 'APPLE',
  /** chargify */
  Chargify = 'CHARGIFY',
  /** viewsonic */
  Viewsonic = 'VIEWSONIC',
}

export type AppStoreReceiptData = {
  __typename?: 'AppStoreReceiptData';
  expires_date: Scalars['String']['output'];
  expires_date_ms: Scalars['DateTime']['output'];
  is_trial_period: Scalars['Boolean']['output'];
  original_purchase_date: Scalars['String']['output'];
  original_purchase_date_ms: Scalars['DateTime']['output'];
  original_transaction_id: Scalars['String']['output'];
  product_id: Scalars['String']['output'];
  purchase_date: Scalars['String']['output'];
  purchase_date_ms: Scalars['DateTime']['output'];
  transaction_id: Scalars['String']['output'];
};

export type AppleSubscriptionResponseDto = {
  __typename?: 'AppleSubscriptionResponseDto';
  app_store_receipt_data: AppStoreReceiptData;
  mvb_subscription: UserSubscriptionVo;
  state: AppleSubscriptionState;
};

/** The supported payment type. */
export enum AppleSubscriptionState {
  /** created */
  Created = 'CREATED',
  /** none */
  None = 'NONE',
  /** pending */
  Pending = 'PENDING',
}

/** product are applicable to which target. e.g. personal, entity or all */
export enum ApplicableType {
  /** all */
  All = 'ALL',
  /** entity */
  Entity = 'ENTITY',
  /** personal */
  Personal = 'PERSONAL',
}

export type BillingManifest = {
  __typename?: 'BillingManifest';
  existing_balance: Scalars['String']['output'];
  line_items: Array<LineItems>;
  subtotal: Scalars['String']['output'];
  total: Scalars['String']['output'];
  total_discount: Scalars['String']['output'];
  total_tax: Scalars['String']['output'];
};

export type BundleAddonOutput = {
  __typename?: 'BundleAddonOutput';
  handle: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type BundleOutput = {
  __typename?: 'BundleOutput';
  children: Array<BundleOutput>;
  handle?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  plans: Array<BundlePlanOutput>;
};

export type BundlePlanOutput = {
  __typename?: 'BundlePlanOutput';
  addons?: Maybe<Array<BundleAddonOutput>>;
  details: ProductOutput;
  handle: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

/** cancel subscription reason code */
export enum CancelSubscriptionReasonCode {
  /** Too expensive. */
  Cost = 'COST',
  /** Lacking required feature(s). */
  Features = 'FEATURES',
  /** No Need Now. Will Be Back. */
  Noneed = 'NONEED',
}

export type ComponentPlanBuyingDetail = {
  id: Scalars['String']['input'];
  /** how much quantity want to buy */
  quantity: Scalars['Float']['input'];
};

export type ComponentPlanBuyingDetailVo = {
  __typename?: 'ComponentPlanBuyingDetailVo';
  component_plan_id: Scalars['String']['output'];
  quantity: Scalars['Float']['output'];
};

export type ComponentPlanVo = {
  __typename?: 'ComponentPlanVo';
  basic_quantity: Scalars['Float']['output'];
  create_time: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  duration: Scalars['Float']['output'];
  duration_unit: DurationUnit;
  id: Scalars['String']['output'];
  is_auto_approve: Scalars['Boolean']['output'];
  is_complimentary: Scalars['Boolean']['output'];
  is_default: Scalars['Boolean']['output'];
  is_publish: Scalars['Boolean']['output'];
  modify_time: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  trial_duration: Scalars['Float']['output'];
  trial_duration_unit: DurationUnit;
};

export type ComponentVo = {
  __typename?: 'ComponentVo';
  allowed_payment_source: Array<AllowedPaymentSource>;
  applicable_type: ApplicableType;
  create_time: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  is_publish: Scalars['Boolean']['output'];
  modify_time: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  plans: Array<ComponentPlanVo>;
  unit_name: Scalars['String']['output'];
};

/** The invoice directions. */
export enum Directions {
  Asc = 'ASC',
  Desc = 'DESC',
}

/**  which payment source is allowed to pay for this product, e.g. apple, chargify, viewsonic, can be multiple values at same time. */
export enum DurationUnit {
  /** day */
  Day = 'DAY',
  /** month */
  Month = 'MONTH',
  /** year */
  Year = 'YEAR',
}

export type EntitySubscriptionVo = {
  __typename?: 'EntitySubscriptionVo';
  list: Array<SubscriptionOutput>;
};

export type GetPriceOutput = {
  __typename?: 'GetPriceOutput';
  plan_id: Scalars['String']['output'];
  price: Scalars['String']['output'];
  price_in_cents: Scalars['Float']['output'];
};

/** The supported interval unit. */
export enum IntervalUnit {
  /** The year of the interval unit. */
  Annual = 'Annual',
  /** The month of the interval unit. */
  Monthly = 'Monthly',
}

export type InvoiceCustomerOutput = {
  __typename?: 'InvoiceCustomerOutput';
  address: Scalars['String']['output'];
  address_2: Scalars['String']['output'];
  cc_emails: Scalars['String']['output'];
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  email: Scalars['String']['output'];
  first_name: Scalars['String']['output'];
  id: Scalars['Float']['output'];
  last_name: Scalars['String']['output'];
  organization: Scalars['String']['output'];
  parent_id: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  reference: Scalars['String']['output'];
  state: Scalars['String']['output'];
  vat_number: Scalars['String']['output'];
  zip: Scalars['String']['output'];
};

export type InvoiceDetailsOutput = {
  __typename?: 'InvoiceDetailsOutput';
  collection_method: Scalars['String']['output'];
  customer: InvoiceCustomerOutput;
  customer_id: Scalars['Float']['output'];
  discount_amount: Scalars['String']['output'];
  due_date: Scalars['String']['output'];
  issue_date: Scalars['String']['output'];
  line_items: Array<InvoiceLineItem>;
  number: Scalars['String']['output'];
  paid_amount: Scalars['String']['output'];
  paid_date: Scalars['String']['output'];
  pdf_url: Scalars['String']['output'];
  product_family_name: Scalars['String']['output'];
  product_name: Scalars['String']['output'];
  public_url: Scalars['String']['output'];
  sequence_number: Scalars['Float']['output'];
  site_id: Scalars['Float']['output'];
  status: Scalars['String']['output'];
  subscription_id: Scalars['Float']['output'];
  subtotal_amount: Scalars['String']['output'];
  tax_amount: Scalars['String']['output'];
  total_amount: Scalars['String']['output'];
  uid: Scalars['String']['output'];
};

export type InvoiceItem = {
  __typename?: 'InvoiceItem';
  customer: InvoiceCustomerOutput;
  due_date: Scalars['String']['output'];
  issue_date: Scalars['String']['output'];
  line_items: Array<InvoiceLineItem>;
  /** The invoice number. */
  number: Scalars['String']['output'];
  paid_date: Scalars['String']['output'];
  pdf_url: Scalars['String']['output'];
  total_amount: Scalars['String']['output'];
  uid: Scalars['String']['output'];
};

export type InvoiceLineItem = {
  __typename?: 'InvoiceLineItem';
  description: Scalars['String']['output'];
  period_range_end: Scalars['String']['output'];
  period_range_start: Scalars['String']['output'];
};

export type InvoiceOutput = {
  __typename?: 'InvoiceOutput';
  current_page: Scalars['Float']['output'];
  invoices: Array<InvoiceItem>;
  page_size: Scalars['Float']['output'];
  total_invoice_count: Scalars['Float']['output'];
  total_pages: Scalars['Float']['output'];
};

export type InvoicePaymentMethod = {
  __typename?: 'InvoicePaymentMethod';
  masked_card_number: Scalars['String']['output'];
};

export type LineItems = {
  __typename?: 'LineItems';
  amount_in_cents: Scalars['Float']['output'];
  component_handle: Scalars['String']['output'];
  component_id: Scalars['Float']['output'];
  component_name: Scalars['String']['output'];
  discount_amount_in_cents: Scalars['Float']['output'];
  product_handle: Scalars['String']['output'];
  product_id: Scalars['Float']['output'];
  product_name: Scalars['String']['output'];
  taxable_amount_in_cents: Scalars['Float']['output'];
  taxations: Array<Maybe<Taxations>>;
};

export type MvbProductOutput = {
  __typename?: 'MVBProductOutput';
  accounting_code: Scalars['String']['output'];
  area: Scalars['String']['output'];
  description: Scalars['String']['output'];
  expiration_interval_unit: Scalars['String']['output'];
  handle: Scalars['String']['output'];
  id: Scalars['Float']['output'];
  initial_charge: Scalars['String']['output'];
  initial_charge_after_trial: Scalars['Boolean']['output'];
  interval: Scalars['Float']['output'];
  interval_unit: Scalars['String']['output'];
  name: Scalars['String']['output'];
  price: Scalars['String']['output'];
  price_in_cents: Scalars['Float']['output'];
  product_family: ProductFamilyOutput;
  product_price_point_id: Scalars['Float']['output'];
  trial_interval: Scalars['Float']['output'];
  trial_interval_unit: Scalars['String']['output'];
  trial_price: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Apple Change product (upgrade / downgrade) */
  apple_migrate: Scalars['String']['output'];
  /** Apple Subscribe */
  apple_subscribe: AppleSubscriptionResponseDto;
  /** Apple Unsubscribe */
  apple_unsubscribe: Scalars['String']['output'];
  /** Chargify add coupon */
  chargify_add_coupon: Scalars['Boolean']['output'];
  /** now only support one credit card */
  chargify_create_payment_profile: PaymentProfileOutput;
  chargify_delete_payment_profile: Scalars['Boolean']['output'];
  /** Chargify Change product (upgrade / downgrade) */
  chargify_migrate: Scalars['Boolean']['output'];
  /** Chargify remove coupon */
  chargify_remove_coupon: Scalars['Boolean']['output'];
  /** Chargify Subscribe back (undo unsubscribe) */
  chargify_resume: Scalars['Boolean']['output'];
  /** Chargify Subscribe */
  chargify_subscribe: UserSubscriptionVo;
  /** Chargify Unsubscribe */
  chargify_unsubscribe: Scalars['Boolean']['output'];
  chargify_update_payment_profile: PaymentProfileOutput;
  /** Admin Portal Create Component */
  createComponent: ComponentVo;
  /** Admin Portal Create Component Plan */
  createComponentPlan: ComponentPlanVo;
  /** Admin Portal Create Product */
  createProduct: ProductVo;
  /** Admin Portal Create Product Family */
  createProductFamily: ProductFamilyVo;
  /** Admin Portal Create Product Plan */
  createProductPlan: ProductPlanVo;
  /** Admin Portal Delete Component */
  deleteComponent: Scalars['String']['output'];
  /** Admin Portal Delete Component Plan */
  deleteComponentPlan: Scalars['String']['output'];
  /** Admin Portal Delete Product */
  deleteProduct: Scalars['String']['output'];
  /** Admin Portal Delete Product Family */
  deleteProductFamily: Scalars['String']['output'];
  /** Admin Portal Delete Product Plan */
  deleteProductPlan: Scalars['String']['output'];
  sync_product: Scalars['Boolean']['output'];
  sync_product_action: Scalars['Boolean']['output'];
  /** Admin Portal Update Component */
  updateComponent: Scalars['String']['output'];
  /** Admin Portal Update Component Plan */
  updateComponentPlan: Scalars['String']['output'];
  /** Admin Portal Update Product */
  updateProduct: Scalars['String']['output'];
  /** Admin Portal Update Product Family */
  updateProductFamily: Scalars['String']['output'];
  /** Admin Portal Update Product Plan */
  updateProductPlan: Scalars['String']['output'];
  /** MVB onBoard Request Create, been used by sales-portal, end-user */
  viewsonic_request_create: Scalars['String']['output'];
  /** MVB onBoard Request Update, been used by sales-portal, end-user */
  viewsonic_request_update: Scalars['String']['output'];
};

export type MutationApple_MigrateArgs = {
  product_handle: Scalars['String']['input'];
  subscription_client_type: SubscriptionClientType;
  subscription_id: Scalars['Float']['input'];
};

export type MutationApple_SubscribeArgs = {
  product_id: IntervalUnit;
  receipt?: InputMaybe<Scalars['String']['input']>;
  subscription_client_type: SubscriptionClientType;
};

export type MutationApple_UnsubscribeArgs = {
  id: Scalars['String']['input'];
  reason: Scalars['String']['input'];
  subscription_client_type: SubscriptionClientType;
};

export type MutationChargify_Add_CouponArgs = {
  coupon_codes: Array<Scalars['String']['input']>;
  subscription_client_type: SubscriptionClientType;
  subscription_id: Scalars['String']['input'];
};

export type MutationChargify_Create_Payment_ProfileArgs = {
  token: Scalars['String']['input'];
};

export type MutationChargify_Delete_Payment_ProfileArgs = {
  id: Scalars['Float']['input'];
};

export type MutationChargify_MigrateArgs = {
  product_plan_id: Scalars['String']['input'];
  subscription_client_type: SubscriptionClientType;
  subscription_id: Scalars['String']['input'];
};

export type MutationChargify_Remove_CouponArgs = {
  coupon_code: Scalars['String']['input'];
  subscription_client_type: SubscriptionClientType;
  subscription_id: Scalars['String']['input'];
};

export type MutationChargify_ResumeArgs = {
  subscription_client_type: SubscriptionClientType;
  subscription_id: Scalars['String']['input'];
};

export type MutationChargify_SubscribeArgs = {
  component_plans?: InputMaybe<Array<ComponentPlanBuyingDetail>>;
  coupon_codes?: InputMaybe<Array<Scalars['String']['input']>>;
  payment_id?: InputMaybe<Scalars['Float']['input']>;
  payment_type: PaymentType;
  product_plan_id?: InputMaybe<Scalars['String']['input']>;
  subscription_client_type: SubscriptionClientType;
  token?: InputMaybe<Scalars['String']['input']>;
};

export type MutationChargify_UnsubscribeArgs = {
  reason_code?: InputMaybe<CancelSubscriptionReasonCode>;
  subscription_client_type: SubscriptionClientType;
  subscription_ids: Array<Scalars['String']['input']>;
};

export type MutationChargify_Update_Payment_ProfileArgs = {
  id: Scalars['Float']['input'];
  token: Scalars['String']['input'];
};

export type MutationCreateComponentArgs = {
  allowed_payment_source: Array<AllowedPaymentSource>;
  applicable_type: ApplicableType;
  description?: InputMaybe<Scalars['String']['input']>;
  is_publish: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  product_family_id: Scalars['String']['input'];
  unit_name: Scalars['String']['input'];
};

export type MutationCreateComponentPlanArgs = {
  basic_quantity: Scalars['Float']['input'];
  component_id: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  duration: Scalars['Float']['input'];
  duration_unit: DurationUnit;
  is_auto_approve: Scalars['Boolean']['input'];
  is_complimentary: Scalars['Boolean']['input'];
  is_default: Scalars['Boolean']['input'];
  is_publish: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  trial_duration: Scalars['Float']['input'];
  trial_duration_unit: DurationUnit;
};

export type MutationCreateProductArgs = {
  allowed_payment_source: Array<AllowedPaymentSource>;
  applicable_type: ApplicableType;
  description?: InputMaybe<Scalars['String']['input']>;
  is_publish: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  product_family_id: Scalars['String']['input'];
};

export type MutationCreateProductFamilyArgs = {
  description: Scalars['String']['input'];
  display_icon_url?: InputMaybe<Scalars['String']['input']>;
  is_sync_to_vendor?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
};

export type MutationCreateProductPlanArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  duration: Scalars['Float']['input'];
  duration_unit: DurationUnit;
  is_auto_approve: Scalars['Boolean']['input'];
  is_complimentary: Scalars['Boolean']['input'];
  is_default: Scalars['Boolean']['input'];
  is_publish: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  product_id: Scalars['String']['input'];
  trial_duration: Scalars['Float']['input'];
  trial_duration_unit: DurationUnit;
};

export type MutationDeleteComponentArgs = {
  component_id: Scalars['String']['input'];
};

export type MutationDeleteComponentPlanArgs = {
  component_plan_id: Scalars['String']['input'];
};

export type MutationDeleteProductArgs = {
  product_id: Scalars['String']['input'];
};

export type MutationDeleteProductFamilyArgs = {
  product_family_id: Scalars['String']['input'];
};

export type MutationDeleteProductPlanArgs = {
  product_plan_id: Scalars['String']['input'];
};

export type MutationSync_ProductArgs = {
  action_token?: InputMaybe<Scalars['String']['input']>;
  component?: InputMaybe<UpdateComponentArgs>;
  component_plan?: InputMaybe<UpdateComponentPlanArgs>;
  product?: InputMaybe<UpdateProductArgs>;
  product_family?: InputMaybe<UpdateProductFamilyArgs>;
  product_plan?: InputMaybe<UpdateProductPlanArgs>;
};

export type MutationSync_Product_ActionArgs = {
  action_token?: InputMaybe<Scalars['String']['input']>;
  component?: InputMaybe<UpdateComponentArgs>;
  component_plan?: InputMaybe<UpdateComponentPlanArgs>;
  product?: InputMaybe<UpdateProductArgs>;
  product_family?: InputMaybe<UpdateProductFamilyArgs>;
  product_plan?: InputMaybe<UpdateProductPlanArgs>;
};

export type MutationUpdateComponentArgs = {
  allowed_payment_source?: InputMaybe<Array<AllowedPaymentSource>>;
  applicable_type?: InputMaybe<ApplicableType>;
  component_id: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  is_publish?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  unit_name?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateComponentPlanArgs = {
  basic_quantity?: InputMaybe<Scalars['Float']['input']>;
  component_plan_id: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['Float']['input']>;
  duration_unit?: InputMaybe<DurationUnit>;
  is_auto_approve?: InputMaybe<Scalars['Boolean']['input']>;
  is_complimentary?: InputMaybe<Scalars['Boolean']['input']>;
  is_default?: InputMaybe<Scalars['Boolean']['input']>;
  is_publish?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  trial_duration?: InputMaybe<Scalars['Float']['input']>;
  trial_duration_unit?: InputMaybe<DurationUnit>;
};

export type MutationUpdateProductArgs = {
  allowed_payment_source?: InputMaybe<Array<AllowedPaymentSource>>;
  applicable_type?: InputMaybe<ApplicableType>;
  description?: InputMaybe<Scalars['String']['input']>;
  is_publish?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  product_id: Scalars['String']['input'];
};

export type MutationUpdateProductFamilyArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  display_icon_url?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  product_family_id: Scalars['String']['input'];
};

export type MutationUpdateProductPlanArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['Float']['input']>;
  duration_unit?: InputMaybe<DurationUnit>;
  is_auto_approve?: InputMaybe<Scalars['Boolean']['input']>;
  is_complimentary?: InputMaybe<Scalars['Boolean']['input']>;
  is_default?: InputMaybe<Scalars['Boolean']['input']>;
  is_publish?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  product_plan_id: Scalars['String']['input'];
  trial_duration?: InputMaybe<Scalars['Float']['input']>;
  trial_duration_unit?: InputMaybe<DurationUnit>;
};

export type MutationViewsonic_Request_CreateArgs = {
  componentPlanId: Scalars['String']['input'];
  entityId: Scalars['String']['input'];
  executeTime: Scalars['DateTime']['input'];
  invoiceNumber: Scalars['String']['input'];
  is_auto_approve: Scalars['String']['input'];
  productPlanId: Scalars['String']['input'];
  quantity: Scalars['Float']['input'];
  subscription_client_type: SubscriptionClientType;
};

export type MutationViewsonic_Request_UpdateArgs = {
  executeTime: Scalars['DateTime']['input'];
  id: Scalars['String']['input'];
  invoiceNumber: Scalars['String']['input'];
  quantity: Scalars['Float']['input'];
  subscription_client_type: SubscriptionClientType;
};

export type PaymentProfileOutput = {
  __typename?: 'PaymentProfileOutput';
  billing_address: Scalars['String']['output'];
  billing_address_2: Scalars['String']['output'];
  billing_city: Scalars['String']['output'];
  billing_country: Scalars['String']['output'];
  billing_state: Scalars['String']['output'];
  billing_zip: Scalars['String']['output'];
  card_type: Scalars['String']['output'];
  customer_id: Scalars['Float']['output'];
  expiration_month: Scalars['Float']['output'];
  expiration_year: Scalars['Float']['output'];
  first_name: Scalars['String']['output'];
  gateway_handle: Scalars['String']['output'];
  id: Scalars['Float']['output'];
  last_name: Scalars['String']['output'];
  masked_card_number: Scalars['String']['output'];
  payment_type: Scalars['String']['output'];
};

/** The supported payment type. */
export enum PaymentType {
  /** Use credit card for payment. */
  CreditCard = 'CreditCard',
}

export type PreviewCreditCardDetail = {
  billing_country?: InputMaybe<Scalars['String']['input']>;
  billing_state?: InputMaybe<Scalars['String']['input']>;
  billing_zip?: InputMaybe<Scalars['String']['input']>;
};

export type PreviewOutput = {
  __typename?: 'PreviewOutput';
  /** migration preview response */
  charge?: Maybe<Scalars['String']['output']>;
  /** migration preview response */
  credit_applied?: Maybe<Scalars['String']['output']>;
  /** preview response */
  current_billing_manifest?: Maybe<BillingManifest>;
  end_time: Scalars['String']['output'];
  /** migration preview response */
  payment_due?: Maybe<Scalars['String']['output']>;
  /** migration preview response */
  prorated_adjustment?: Maybe<Scalars['String']['output']>;
  start_time: Scalars['String']['output'];
};

export type ProductFamilyOutput = {
  __typename?: 'ProductFamilyOutput';
  accounting_code: Scalars['String']['output'];
  created_at: Scalars['String']['output'];
  description: Scalars['String']['output'];
  handle: Scalars['String']['output'];
  id: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  updated_at: Scalars['String']['output'];
};

export type ProductFamilyVo = {
  __typename?: 'ProductFamilyVo';
  components: Array<ComponentVo>;
  create_time: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  display_icon_url?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  modify_time: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  products: Array<ProductVo>;
};

export type ProductOutput = {
  __typename?: 'ProductOutput';
  accounting_code: Scalars['String']['output'];
  description: Scalars['String']['output'];
  expiration_interval_unit: Scalars['String']['output'];
  handle: Scalars['String']['output'];
  id: Scalars['Float']['output'];
  initial_charge: Scalars['String']['output'];
  initial_charge_after_trial: Scalars['Boolean']['output'];
  interval: Scalars['Float']['output'];
  interval_unit: Scalars['String']['output'];
  name: Scalars['String']['output'];
  price: Scalars['String']['output'];
  price_in_cents: Scalars['Float']['output'];
  product_family: ProductFamilyOutput;
  product_price_point_id: Scalars['Float']['output'];
  trial_interval: Scalars['Float']['output'];
  trial_interval_unit: Scalars['String']['output'];
  trial_price: Scalars['String']['output'];
};

export type ProductPlanVo = {
  __typename?: 'ProductPlanVo';
  create_time: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  duration: Scalars['Float']['output'];
  duration_unit: DurationUnit;
  id: Scalars['String']['output'];
  is_auto_approve: Scalars['Boolean']['output'];
  is_complimentary: Scalars['Boolean']['output'];
  is_default: Scalars['Boolean']['output'];
  is_publish: Scalars['Boolean']['output'];
  modify_time: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  trial_duration: Scalars['Float']['output'];
  trial_duration_unit: DurationUnit;
};

export type ProductVo = {
  __typename?: 'ProductVo';
  allowed_payment_source: Array<AllowedPaymentSource>;
  applicable_type: ApplicableType;
  create_time: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  is_publish: Scalars['Boolean']['output'];
  modify_time: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  plans: Array<ProductPlanVo>;
};

export type Query = {
  __typename?: 'Query';
  /** Admin Portal List Product Families */
  adminProductFamilies: Array<ProductFamilyVo>;
  chargify_invoice_details: InvoiceDetailsOutput;
  chargify_invoices: InvoiceOutput;
  /** now only support one credit card */
  chargify_payment_profile: Array<PaymentProfileOutput>;
  chargify_preview: PreviewOutput;
  chargify_price: Array<GetPriceOutput>;
  entity_subscriptions: EntitySubscriptionVo;
  /** Account, Entity Portal List Product Families */
  productFamilies: Array<ProductFamilyVo>;
  userSubscriptions: Array<UserSubscriptionVo>;
};

export type QueryAdminProductFamiliesArgs = {
  names?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QueryChargify_Invoice_DetailsArgs = {
  uid: Scalars['String']['input'];
};

export type QueryChargify_InvoicesArgs = {
  direction?: InputMaybe<Directions>;
  page?: InputMaybe<Scalars['Float']['input']>;
  page_size?: InputMaybe<Scalars['Float']['input']>;
  product_family_id: Scalars['String']['input'];
  subscription_id?: InputMaybe<Scalars['String']['input']>;
};

export type QueryChargify_PreviewArgs = {
  component_plans?: InputMaybe<Array<ComponentPlanBuyingDetail>>;
  coupon_codes?: InputMaybe<Array<Scalars['String']['input']>>;
  credit_card_attributes?: InputMaybe<PreviewCreditCardDetail>;
  product_plan_id?: InputMaybe<Scalars['String']['input']>;
  subscription_client_type: SubscriptionClientType;
  subscription_id?: InputMaybe<Scalars['String']['input']>;
};

export type QueryChargify_PriceArgs = {
  product_family_id: Scalars['String']['input'];
  subscription_client_type: SubscriptionClientType;
};

export type QueryEntity_SubscriptionsArgs = {
  entity_id: Scalars['String']['input'];
};

export type QueryProductFamiliesArgs = {
  names?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QueryUserSubscriptionsArgs = {
  familyNames?: InputMaybe<Array<Scalars['String']['input']>>;
  status?: InputMaybe<Array<SubscriptionStatus>>;
};

export type Subscription = {
  __typename?: 'Subscription';
  /** MVB onBoard Request successful event */
  mvb_request_event: Scalars['String']['output'];
};

/** The supported subscription client type. */
export enum SubscriptionClientType {
  /** School or Organization etc. */
  Entity = 'Entity',
  /** End user. */
  Personal = 'Personal',
}

export type SubscriptionOutput = {
  __typename?: 'SubscriptionOutput';
  details: MvbProductOutput;
  expiry_at: Scalars['String']['output'];
  id: Scalars['String']['output'];
  next_product_handle: Scalars['String']['output'];
  start_at: Scalars['String']['output'];
  state: Scalars['String']['output'];
  trial_ended_at: Scalars['String']['output'];
  trial_started_at: Scalars['String']['output'];
};

/** subscription status */
export enum SubscriptionStatus {
  /** active */
  Active = 'ACTIVE',
  /** cancelled */
  Cancelled = 'CANCELLED',
  /** directly cancelled */
  DirectlyCancelled = 'DIRECTLY_CANCELLED',
  /** missing payment */
  MissingPayment = 'MISSING_PAYMENT',
  /** pending cancelled */
  PendingCancelled = 'PENDING_CANCELLED',
  /** pending payment */
  PendingPayment = 'PENDING_PAYMENT',
  /** trialing */
  Trialing = 'TRIALING',
  /** trial ended */
  TrialEnded = 'TRIAL_ENDED',
  /** trial pending cancelled */
  TrialPendingCancelled = 'TRIAL_PENDING_CANCELLED',
}

export type Taxations = {
  __typename?: 'Taxations';
  rate: Scalars['String']['output'];
  tax_amount_in_cents: Scalars['Float']['output'];
  tax_id: Scalars['String']['output'];
  tax_name: Scalars['String']['output'];
};

export type UpdateComponentArgs = {
  allowed_payment_source?: InputMaybe<Array<AllowedPaymentSource>>;
  applicable_type?: InputMaybe<ApplicableType>;
  component_id: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  is_publish?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  unit_name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateComponentPlanArgs = {
  basic_quantity?: InputMaybe<Scalars['Float']['input']>;
  component_plan_id: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['Float']['input']>;
  duration_unit?: InputMaybe<DurationUnit>;
  is_auto_approve?: InputMaybe<Scalars['Boolean']['input']>;
  is_complimentary?: InputMaybe<Scalars['Boolean']['input']>;
  is_default?: InputMaybe<Scalars['Boolean']['input']>;
  is_publish?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  trial_duration?: InputMaybe<Scalars['Float']['input']>;
  trial_duration_unit?: InputMaybe<DurationUnit>;
};

export type UpdateProductArgs = {
  allowed_payment_source?: InputMaybe<Array<AllowedPaymentSource>>;
  applicable_type?: InputMaybe<ApplicableType>;
  description?: InputMaybe<Scalars['String']['input']>;
  is_publish?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  product_id: Scalars['String']['input'];
};

export type UpdateProductFamilyArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  display_icon_url?: InputMaybe<Scalars['String']['input']>;
  /** specific product family name, e.g. device-manager, original, entity, mvb */
  name?: InputMaybe<Scalars['String']['input']>;
  product_family_id: Scalars['String']['input'];
};

export type UpdateProductPlanArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['Float']['input']>;
  duration_unit?: InputMaybe<DurationUnit>;
  is_auto_approve?: InputMaybe<Scalars['Boolean']['input']>;
  is_complimentary?: InputMaybe<Scalars['Boolean']['input']>;
  is_default?: InputMaybe<Scalars['Boolean']['input']>;
  is_publish?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  product_plan_id: Scalars['String']['input'];
  trial_duration?: InputMaybe<Scalars['Float']['input']>;
  trial_duration_unit?: InputMaybe<DurationUnit>;
};

export type UserSubscriptionOnboardRequestVo = {
  __typename?: 'UserSubscriptionOnboardRequestVo';
  approve_time: Scalars['DateTime']['output'];
  comment: Scalars['String']['output'];
  creator_id: Scalars['String']['output'];
  description: Scalars['String']['output'];
  effective_method: Scalars['String']['output'];
  effective_time: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  product_plan: ProductPlanVo;
  status: Scalars['String']['output'];
  user_id: Scalars['String']['output'];
  workplace_post_id: Scalars['String']['output'];
};

export type UserSubscriptionVo = {
  __typename?: 'UserSubscriptionVo';
  component_detail?: Maybe<Array<ComponentPlanBuyingDetailVo>>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  next_billing_time: Scalars['DateTime']['output'];
  onboard_request?: Maybe<UserSubscriptionOnboardRequestVo>;
  payment_event_id?: Maybe<Scalars['String']['output']>;
  payment_source: Scalars['String']['output'];
  product_plan?: Maybe<ProductPlanVo>;
  product_plan_id?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  trial_end_time?: Maybe<Scalars['DateTime']['output']>;
  trial_start_time?: Maybe<Scalars['DateTime']['output']>;
  user_id: Scalars['String']['output'];
  vendorRealTimeData?: Maybe<VendorRealTimeSubscriptionData>;
};

export type VendorRealTimeProductFamilyData = {
  __typename?: 'VendorRealTimeProductFamilyData';
  accounting_code?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  handle?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['String']['output']>;
};

export type VendorRealTimeSubscriptionData = {
  __typename?: 'VendorRealTimeSubscriptionData';
  couponCodeApplied?: Maybe<Scalars['String']['output']>;
  couponUseCount?: Maybe<Scalars['Float']['output']>;
  couponUsesAllowed?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  isDelayedCancel?: Maybe<Scalars['Boolean']['output']>;
  nextProductPlanHandle?: Maybe<Scalars['String']['output']>;
  nextProductPlanName?: Maybe<Scalars['String']['output']>;
};

export type AdminCreateProductFamiliesMutationVariables = Exact<{
  name: Scalars['String']['input'];
  description: Scalars['String']['input'];
  displayIconUrl?: InputMaybe<Scalars['String']['input']>;
}>;

export type AdminCreateProductFamiliesMutation = {
  __typename?: 'Mutation';
  createProductFamily: {
    __typename?: 'ProductFamilyVo';
    id: string;
    name: string;
    description?: string | null;
    display_icon_url?: string | null;
    create_time: any;
    modify_time: any;
  };
};

export type AdminUpdateProductFamilyMutationVariables = Exact<{
  productFamilyId: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayIconUrl?: InputMaybe<Scalars['String']['input']>;
}>;

export type AdminUpdateProductFamilyMutation = {
  __typename?: 'Mutation';
  updateProductFamily: string;
};

export type AdminDeleteProductFamiliesMutationVariables = Exact<{
  productFamilyId: Scalars['String']['input'];
}>;

export type AdminDeleteProductFamiliesMutation = {
  __typename?: 'Mutation';
  deleteProductFamily: string;
};

export type AdminCreateProductMutationVariables = Exact<{
  productFamilyId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  isPublish: Scalars['Boolean']['input'];
  applicableType: ApplicableType;
  allowedPaymentSource: Array<AllowedPaymentSource> | AllowedPaymentSource;
  description?: InputMaybe<Scalars['String']['input']>;
}>;

export type AdminCreateProductMutation = {
  __typename?: 'Mutation';
  createProduct: {
    __typename?: 'ProductVo';
    id: string;
    name: string;
    description?: string | null;
    is_publish: boolean;
    applicable_type: ApplicableType;
    allowed_payment_source: Array<AllowedPaymentSource>;
    create_time: any;
    modify_time: any;
  };
};

export type AdminUpdateProductMutationVariables = Exact<{
  productId: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  isPublish?: InputMaybe<Scalars['Boolean']['input']>;
  applicableType?: InputMaybe<ApplicableType>;
  allowedPaymentSource?: InputMaybe<
    Array<AllowedPaymentSource> | AllowedPaymentSource
  >;
}>;

export type AdminUpdateProductMutation = {
  __typename?: 'Mutation';
  updateProduct: string;
};

export type AdminToggleProductPublishMutationVariables = Exact<{
  productId: Scalars['String']['input'];
  isPublish?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type AdminToggleProductPublishMutation = {
  __typename?: 'Mutation';
  updateProduct: string;
};

export type AdminDeleteProductMutationVariables = Exact<{
  productId: Scalars['String']['input'];
}>;

export type AdminDeleteProductMutation = {
  __typename?: 'Mutation';
  deleteProduct: string;
};

export type AdminCreateProductPlanMutationVariables = Exact<{
  productId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  duration: Scalars['Float']['input'];
  durationUnit: DurationUnit;
  trialDuration: Scalars['Float']['input'];
  trialDurationUnit: DurationUnit;
  isDefault: Scalars['Boolean']['input'];
  isPublish: Scalars['Boolean']['input'];
  isComplimentary: Scalars['Boolean']['input'];
  isAutoApprove: Scalars['Boolean']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
}>;

export type AdminCreateProductPlanMutation = {
  __typename?: 'Mutation';
  createProductPlan: {
    __typename?: 'ProductPlanVo';
    id: string;
    modify_time: any;
    create_time: any;
    name: string;
    description?: string | null;
    duration: number;
    duration_unit: DurationUnit;
    trial_duration: number;
    trial_duration_unit: DurationUnit;
    is_default: boolean;
    is_publish: boolean;
    is_complimentary: boolean;
    is_auto_approve: boolean;
  };
};

export type AdminUpdateProductPlanMutationVariables = Exact<{
  productPlanId: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['Float']['input']>;
  durationUnit?: InputMaybe<DurationUnit>;
  trialDuration?: InputMaybe<Scalars['Float']['input']>;
  trialDurationUnit?: InputMaybe<DurationUnit>;
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  isPublish?: InputMaybe<Scalars['Boolean']['input']>;
  isComplimentary?: InputMaybe<Scalars['Boolean']['input']>;
  isAutoApprove?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type AdminUpdateProductPlanMutation = {
  __typename?: 'Mutation';
  updateProductPlan: string;
};

export type AdminToggleProductPlanPublishMutationVariables = Exact<{
  productPlanId: Scalars['String']['input'];
  isPublish?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type AdminToggleProductPlanPublishMutation = {
  __typename?: 'Mutation';
  updateProductPlan: string;
};

export type AdminDeleteProductPlanMutationVariables = Exact<{
  deleteProductPlanProductPlanId2: Scalars['String']['input'];
}>;

export type AdminDeleteProductPlanMutation = {
  __typename?: 'Mutation';
  deleteProductPlan: string;
};

export type AdminCreateComponentMutationVariables = Exact<{
  productFamilyId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  isPublish: Scalars['Boolean']['input'];
  unitName: Scalars['String']['input'];
  applicableType: ApplicableType;
  allowedPaymentSource: Array<AllowedPaymentSource> | AllowedPaymentSource;
  description?: InputMaybe<Scalars['String']['input']>;
}>;

export type AdminCreateComponentMutation = {
  __typename?: 'Mutation';
  createComponent: {
    __typename?: 'ComponentVo';
    id: string;
    modify_time: any;
    create_time: any;
    allowed_payment_source: Array<AllowedPaymentSource>;
    applicable_type: ApplicableType;
    is_publish: boolean;
    unit_name: string;
    description?: string | null;
    name: string;
  };
};

export type AdminUpdateComponentMutationVariables = Exact<{
  componentId: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  isPublish?: InputMaybe<Scalars['Boolean']['input']>;
  unitName?: InputMaybe<Scalars['String']['input']>;
  applicableType?: InputMaybe<ApplicableType>;
  allowedPaymentSource?: InputMaybe<
    Array<AllowedPaymentSource> | AllowedPaymentSource
  >;
}>;

export type AdminUpdateComponentMutation = {
  __typename?: 'Mutation';
  updateComponent: string;
};

export type AdminToggleComponentPublishMutationVariables = Exact<{
  componentId: Scalars['String']['input'];
  isPublish?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type AdminToggleComponentPublishMutation = {
  __typename?: 'Mutation';
  updateComponent: string;
};

export type AdminDeleteComponentMutationVariables = Exact<{
  componentId: Scalars['String']['input'];
}>;

export type AdminDeleteComponentMutation = {
  __typename?: 'Mutation';
  deleteComponent: string;
};

export type AdminCreateComponentPlanMutationVariables = Exact<{
  componentId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  duration: Scalars['Float']['input'];
  durationUnit: DurationUnit;
  basicQuantity: Scalars['Float']['input'];
  trialDuration: Scalars['Float']['input'];
  trialDurationUnit: DurationUnit;
  isDefault: Scalars['Boolean']['input'];
  isPublish: Scalars['Boolean']['input'];
  isComplimentary: Scalars['Boolean']['input'];
  isAutoApprove: Scalars['Boolean']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
}>;

export type AdminCreateComponentPlanMutation = {
  __typename?: 'Mutation';
  createComponentPlan: {
    __typename?: 'ComponentPlanVo';
    id: string;
    name: string;
    description?: string | null;
    duration: number;
    duration_unit: DurationUnit;
    basic_quantity: number;
    trial_duration: number;
    trial_duration_unit: DurationUnit;
    is_default: boolean;
    is_publish: boolean;
    is_complimentary: boolean;
    is_auto_approve: boolean;
    create_time: any;
    modify_time: any;
  };
};

export type AdminUpdateComponentPlanMutationVariables = Exact<{
  componentPlanId: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['Float']['input']>;
  durationUnit?: InputMaybe<DurationUnit>;
  basicQuantity?: InputMaybe<Scalars['Float']['input']>;
  trialDuration?: InputMaybe<Scalars['Float']['input']>;
  trialDurationUnit?: InputMaybe<DurationUnit>;
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  isPublish?: InputMaybe<Scalars['Boolean']['input']>;
  isComplimentary?: InputMaybe<Scalars['Boolean']['input']>;
  isAutoApprove?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type AdminUpdateComponentPlanMutation = {
  __typename?: 'Mutation';
  updateComponentPlan: string;
};

export type AdminToggleComponentPlanPublishMutationVariables = Exact<{
  componentPlanId: Scalars['String']['input'];
  isPublish?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type AdminToggleComponentPlanPublishMutation = {
  __typename?: 'Mutation';
  updateComponentPlan: string;
};

export type AdminDeleteComponentPlanMutationVariables = Exact<{
  componentPlanId: Scalars['String']['input'];
}>;

export type AdminDeleteComponentPlanMutation = {
  __typename?: 'Mutation';
  deleteComponentPlan: string;
};

export type ChargifyCreatePaymentProfileMutationVariables = Exact<{
  token: Scalars['String']['input'];
}>;

export type ChargifyCreatePaymentProfileMutation = {
  __typename?: 'Mutation';
  chargify_create_payment_profile: {
    __typename?: 'PaymentProfileOutput';
    id: number;
    first_name: string;
    last_name: string;
    customer_id: number;
    billing_address: string;
    billing_address_2: string;
    billing_city: string;
    billing_state: string;
    billing_zip: string;
    billing_country: string;
    masked_card_number: string;
    payment_type: string;
    card_type: string;
    expiration_month: number;
    expiration_year: number;
    gateway_handle: string;
  };
};

export type ChargifyUpdatePaymentProfileMutationVariables = Exact<{
  token: Scalars['String']['input'];
  chargifyUpdatePaymentProfileId: Scalars['Float']['input'];
}>;

export type ChargifyUpdatePaymentProfileMutation = {
  __typename?: 'Mutation';
  chargify_update_payment_profile: {
    __typename?: 'PaymentProfileOutput';
    id: number;
    first_name: string;
    last_name: string;
    customer_id: number;
    billing_address: string;
    billing_address_2: string;
    billing_city: string;
    billing_state: string;
    billing_zip: string;
    billing_country: string;
    masked_card_number: string;
    payment_type: string;
    card_type: string;
    expiration_month: number;
    expiration_year: number;
    gateway_handle: string;
  };
};

export type ChargifyDeletePaymentProfileMutationVariables = Exact<{
  chargifyDeletePaymentProfileId: Scalars['Float']['input'];
}>;

export type ChargifyDeletePaymentProfileMutation = {
  __typename?: 'Mutation';
  chargify_delete_payment_profile: boolean;
};

export type ChargifySubscribeMutationVariables = Exact<{
  subscriptionClientType: SubscriptionClientType;
  paymentType: PaymentType;
  productPlanId?: InputMaybe<Scalars['String']['input']>;
  componentPlans?: InputMaybe<
    Array<ComponentPlanBuyingDetail> | ComponentPlanBuyingDetail
  >;
  token?: InputMaybe<Scalars['String']['input']>;
  paymentId?: InputMaybe<Scalars['Float']['input']>;
  couponCodes?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
}>;

export type ChargifySubscribeMutation = {
  __typename?: 'Mutation';
  chargify_subscribe: {
    __typename?: 'UserSubscriptionVo';
    description?: string | null;
    id: string;
    name: string;
    next_billing_time: any;
    payment_event_id?: string | null;
    payment_source: string;
    product_plan_id?: string | null;
    status: string;
    trial_end_time?: any | null;
    trial_start_time?: any | null;
    user_id: string;
  };
};

export type GoTrailMutationVariables = Exact<{
  subscriptionClientType: SubscriptionClientType;
  productPlanId?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  paymentType: PaymentType;
}>;

export type GoTrailMutation = {
  __typename?: 'Mutation';
  chargify_subscribe: {
    __typename?: 'UserSubscriptionVo';
    id: string;
    name: string;
  };
};

export type UnsubscribeMutationVariables = Exact<{
  subscriptionIds:
    | Array<Scalars['String']['input']>
    | Scalars['String']['input'];
  subscriptionClientType: SubscriptionClientType;
}>;

export type UnsubscribeMutation = {
  __typename?: 'Mutation';
  chargify_unsubscribe: boolean;
};

export type ChargifyResumeMutationVariables = Exact<{
  subscriptionClientType: SubscriptionClientType;
  subscriptionId: Scalars['String']['input'];
}>;

export type ChargifyResumeMutation = {
  __typename?: 'Mutation';
  chargify_resume: boolean;
};

export type ChargifyMigrateMutationVariables = Exact<{
  subscriptionClientType: SubscriptionClientType;
  productPlanId: Scalars['String']['input'];
  subscriptionId: Scalars['String']['input'];
}>;

export type ChargifyMigrateMutation = {
  __typename?: 'Mutation';
  chargify_migrate: boolean;
};

export type ChargifyAddCouponMutationVariables = Exact<{
  subscriptionClientType: SubscriptionClientType;
  subscriptionId: Scalars['String']['input'];
  couponCodes: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type ChargifyAddCouponMutation = {
  __typename?: 'Mutation';
  chargify_add_coupon: boolean;
};

export type ChargifyRemoveCouponMutationVariables = Exact<{
  couponCode: Scalars['String']['input'];
  subscriptionId: Scalars['String']['input'];
  subscriptionClientType: SubscriptionClientType;
}>;

export type ChargifyRemoveCouponMutation = {
  __typename?: 'Mutation';
  chargify_remove_coupon: boolean;
};

export type AdminProductFamiliesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type AdminProductFamiliesQuery = {
  __typename?: 'Query';
  adminProductFamilies: Array<{
    __typename?: 'ProductFamilyVo';
    id: string;
    name: string;
    description?: string | null;
    display_icon_url?: string | null;
    create_time: any;
    modify_time: any;
    products: Array<{
      __typename?: 'ProductVo';
      id: string;
      name: string;
      description?: string | null;
      is_publish: boolean;
      applicable_type: ApplicableType;
      allowed_payment_source: Array<AllowedPaymentSource>;
      create_time: any;
      modify_time: any;
      plans: Array<{
        __typename?: 'ProductPlanVo';
        id: string;
        name: string;
        description?: string | null;
        duration: number;
        duration_unit: DurationUnit;
        trial_duration: number;
        trial_duration_unit: DurationUnit;
        is_default: boolean;
        is_publish: boolean;
        is_complimentary: boolean;
        is_auto_approve: boolean;
        create_time: any;
        modify_time: any;
      }>;
    }>;
    components: Array<{
      __typename?: 'ComponentVo';
      id: string;
      name: string;
      description?: string | null;
      unit_name: string;
      is_publish: boolean;
      applicable_type: ApplicableType;
      allowed_payment_source: Array<AllowedPaymentSource>;
      create_time: any;
      modify_time: any;
      plans: Array<{
        __typename?: 'ComponentPlanVo';
        id: string;
        name: string;
        description?: string | null;
        duration: number;
        duration_unit: DurationUnit;
        basic_quantity: number;
        trial_duration: number;
        trial_duration_unit: DurationUnit;
        is_default: boolean;
        is_publish: boolean;
        is_complimentary: boolean;
        is_auto_approve: boolean;
        create_time: any;
        modify_time: any;
      }>;
    }>;
  }>;
};

export type PaymentProfileQueryVariables = Exact<{ [key: string]: never }>;

export type PaymentProfileQuery = {
  __typename?: 'Query';
  chargify_payment_profile: Array<{
    __typename?: 'PaymentProfileOutput';
    id: number;
    first_name: string;
    last_name: string;
    customer_id: number;
    billing_address: string;
    billing_address_2: string;
    billing_city: string;
    billing_state: string;
    billing_zip: string;
    billing_country: string;
    masked_card_number: string;
    payment_type: string;
    card_type: string;
    expiration_month: number;
    expiration_year: number;
    gateway_handle: string;
  }>;
};

export type ProductFamiliesQueryVariables = Exact<{ [key: string]: never }>;

export type ProductFamiliesQuery = {
  __typename?: 'Query';
  productFamilies: Array<{
    __typename?: 'ProductFamilyVo';
    id: string;
    name: string;
    description?: string | null;
    display_icon_url?: string | null;
    create_time: any;
    modify_time: any;
    products: Array<{
      __typename?: 'ProductVo';
      id: string;
      name: string;
      description?: string | null;
      is_publish: boolean;
      applicable_type: ApplicableType;
      allowed_payment_source: Array<AllowedPaymentSource>;
      create_time: any;
      modify_time: any;
      plans: Array<{
        __typename?: 'ProductPlanVo';
        id: string;
        name: string;
        description?: string | null;
        duration: number;
        duration_unit: DurationUnit;
        trial_duration: number;
        trial_duration_unit: DurationUnit;
        is_default: boolean;
        is_publish: boolean;
        is_complimentary: boolean;
        is_auto_approve: boolean;
        create_time: any;
        modify_time: any;
      }>;
    }>;
    components: Array<{
      __typename?: 'ComponentVo';
      id: string;
      name: string;
      description?: string | null;
      unit_name: string;
      is_publish: boolean;
      applicable_type: ApplicableType;
      allowed_payment_source: Array<AllowedPaymentSource>;
      create_time: any;
      modify_time: any;
      plans: Array<{
        __typename?: 'ComponentPlanVo';
        id: string;
        name: string;
        description?: string | null;
        duration: number;
        duration_unit: DurationUnit;
        basic_quantity: number;
        trial_duration: number;
        trial_duration_unit: DurationUnit;
        is_default: boolean;
        is_publish: boolean;
        is_complimentary: boolean;
        is_auto_approve: boolean;
        create_time: any;
        modify_time: any;
      }>;
    }>;
  }>;
};

export type ProductFamiliesListQueryVariables = Exact<{ [key: string]: never }>;

export type ProductFamiliesListQuery = {
  __typename?: 'Query';
  productFamilies: Array<{
    __typename?: 'ProductFamilyVo';
    id: string;
    name: string;
    display_icon_url?: string | null;
  }>;
};

export type UserSubscriptionsQueryVariables = Exact<{
  familyNames?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  status?: InputMaybe<Array<SubscriptionStatus> | SubscriptionStatus>;
}>;

export type UserSubscriptionsQuery = {
  __typename?: 'Query';
  userSubscriptions: Array<{
    __typename?: 'UserSubscriptionVo';
    id: string;
    user_id: string;
    product_plan_id?: string | null;
    name: string;
    description?: string | null;
    status: string;
    next_billing_time: any;
    trial_start_time?: any | null;
    trial_end_time?: any | null;
    payment_source: string;
    payment_event_id?: string | null;
  }>;
};

export type UserSubscriptionsRealTimeDataQueryVariables = Exact<{
  familyNames?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  status?: InputMaybe<Array<SubscriptionStatus> | SubscriptionStatus>;
}>;

export type UserSubscriptionsRealTimeDataQuery = {
  __typename?: 'Query';
  userSubscriptions: Array<{
    __typename?: 'UserSubscriptionVo';
    id: string;
    vendorRealTimeData?: {
      __typename?: 'VendorRealTimeSubscriptionData';
      id?: number | null;
      nextProductPlanHandle?: string | null;
      nextProductPlanName?: string | null;
      couponUseCount?: number | null;
      couponUsesAllowed?: number | null;
      couponCodeApplied?: string | null;
    } | null;
  }>;
};

export type UserInvoicesQueryVariables = Exact<{
  productFamilyId: Scalars['String']['input'];
  subscriptionId?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Float']['input']>;
  pageSize?: InputMaybe<Scalars['Float']['input']>;
  direction?: InputMaybe<Directions>;
}>;

export type UserInvoicesQuery = {
  __typename?: 'Query';
  chargify_invoices: {
    __typename?: 'InvoiceOutput';
    current_page: number;
    page_size: number;
    total_invoice_count: number;
    total_pages: number;
    invoices: Array<{
      __typename?: 'InvoiceItem';
      number: string;
      pdf_url: string;
      total_amount: string;
      paid_date: string;
      line_items: Array<{
        __typename?: 'InvoiceLineItem';
        period_range_end: string;
        period_range_start: string;
        description: string;
      }>;
    }>;
  };
};

export type ChargifyPreviewQueryVariables = Exact<{
  subscriptionClientType: SubscriptionClientType;
  subscriptionId?: InputMaybe<Scalars['String']['input']>;
  productPlanId?: InputMaybe<Scalars['String']['input']>;
  couponCodes?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  creditCardAttributes?: InputMaybe<PreviewCreditCardDetail>;
  componentPlans?: InputMaybe<
    Array<ComponentPlanBuyingDetail> | ComponentPlanBuyingDetail
  >;
}>;

export type ChargifyPreviewQuery = {
  __typename?: 'Query';
  chargify_preview: {
    __typename?: 'PreviewOutput';
    start_time: string;
    end_time: string;
    charge?: string | null;
    credit_applied?: string | null;
    payment_due?: string | null;
    prorated_adjustment?: string | null;
    current_billing_manifest?: {
      __typename?: 'BillingManifest';
      total: string;
      total_discount: string;
      total_tax: string;
      subtotal: string;
      existing_balance: string;
    } | null;
  };
};

export type ChargifyPriceQueryVariables = Exact<{
  subscriptionClientType: SubscriptionClientType;
  productFamilyId: Scalars['String']['input'];
}>;

export type ChargifyPriceQuery = {
  __typename?: 'Query';
  chargify_price: Array<{
    __typename?: 'GetPriceOutput';
    plan_id: string;
    price: string;
  }>;
};

export const AdminCreateProductFamiliesDocument = `
    mutation AdminCreateProductFamilies($name: String!, $description: String!, $displayIconUrl: String) {
  createProductFamily(
    name: $name
    description: $description
    display_icon_url: $displayIconUrl
  ) {
    id
    name
    description
    display_icon_url
    create_time
    modify_time
  }
}
    `;
export const AdminUpdateProductFamilyDocument = `
    mutation AdminUpdateProductFamily($productFamilyId: String!, $name: String, $description: String, $displayIconUrl: String) {
  updateProductFamily(
    product_family_id: $productFamilyId
    name: $name
    description: $description
    display_icon_url: $displayIconUrl
  )
}
    `;
export const AdminDeleteProductFamiliesDocument = `
    mutation AdminDeleteProductFamilies($productFamilyId: String!) {
  deleteProductFamily(product_family_id: $productFamilyId)
}
    `;
export const AdminCreateProductDocument = `
    mutation AdminCreateProduct($productFamilyId: String!, $name: String!, $isPublish: Boolean!, $applicableType: ApplicableType!, $allowedPaymentSource: [AllowedPaymentSource!]!, $description: String) {
  createProduct(
    product_family_id: $productFamilyId
    name: $name
    is_publish: $isPublish
    applicable_type: $applicableType
    allowed_payment_source: $allowedPaymentSource
    description: $description
  ) {
    id
    name
    description
    is_publish
    applicable_type
    allowed_payment_source
    create_time
    modify_time
  }
}
    `;
export const AdminUpdateProductDocument = `
    mutation AdminUpdateProduct($productId: String!, $name: String, $description: String, $isPublish: Boolean, $applicableType: ApplicableType, $allowedPaymentSource: [AllowedPaymentSource!]) {
  updateProduct(
    product_id: $productId
    name: $name
    description: $description
    is_publish: $isPublish
    applicable_type: $applicableType
    allowed_payment_source: $allowedPaymentSource
  )
}
    `;
export const AdminToggleProductPublishDocument = `
    mutation AdminToggleProductPublish($productId: String!, $isPublish: Boolean) {
  updateProduct(product_id: $productId, is_publish: $isPublish)
}
    `;
export const AdminDeleteProductDocument = `
    mutation AdminDeleteProduct($productId: String!) {
  deleteProduct(product_id: $productId)
}
    `;
export const AdminCreateProductPlanDocument = `
    mutation AdminCreateProductPlan($productId: String!, $name: String!, $duration: Float!, $durationUnit: DurationUnit!, $trialDuration: Float!, $trialDurationUnit: DurationUnit!, $isDefault: Boolean!, $isPublish: Boolean!, $isComplimentary: Boolean!, $isAutoApprove: Boolean!, $description: String) {
  createProductPlan(
    product_id: $productId
    name: $name
    duration: $duration
    duration_unit: $durationUnit
    trial_duration: $trialDuration
    trial_duration_unit: $trialDurationUnit
    is_default: $isDefault
    is_publish: $isPublish
    is_complimentary: $isComplimentary
    is_auto_approve: $isAutoApprove
    description: $description
  ) {
    id
    modify_time
    create_time
    name
    description
    duration
    duration_unit
    trial_duration
    trial_duration_unit
    is_default
    is_publish
    is_complimentary
    is_auto_approve
  }
}
    `;
export const AdminUpdateProductPlanDocument = `
    mutation AdminUpdateProductPlan($productPlanId: String!, $name: String, $description: String, $duration: Float, $durationUnit: DurationUnit, $trialDuration: Float, $trialDurationUnit: DurationUnit, $isDefault: Boolean, $isPublish: Boolean, $isComplimentary: Boolean, $isAutoApprove: Boolean) {
  updateProductPlan(
    product_plan_id: $productPlanId
    name: $name
    description: $description
    duration: $duration
    duration_unit: $durationUnit
    trial_duration: $trialDuration
    trial_duration_unit: $trialDurationUnit
    is_default: $isDefault
    is_publish: $isPublish
    is_complimentary: $isComplimentary
    is_auto_approve: $isAutoApprove
  )
}
    `;
export const AdminToggleProductPlanPublishDocument = `
    mutation AdminToggleProductPlanPublish($productPlanId: String!, $isPublish: Boolean) {
  updateProductPlan(product_plan_id: $productPlanId, is_publish: $isPublish)
}
    `;
export const AdminDeleteProductPlanDocument = `
    mutation AdminDeleteProductPlan($deleteProductPlanProductPlanId2: String!) {
  deleteProductPlan(product_plan_id: $deleteProductPlanProductPlanId2)
}
    `;
export const AdminCreateComponentDocument = `
    mutation AdminCreateComponent($productFamilyId: String!, $name: String!, $isPublish: Boolean!, $unitName: String!, $applicableType: ApplicableType!, $allowedPaymentSource: [AllowedPaymentSource!]!, $description: String) {
  createComponent(
    product_family_id: $productFamilyId
    name: $name
    is_publish: $isPublish
    unit_name: $unitName
    applicable_type: $applicableType
    allowed_payment_source: $allowedPaymentSource
    description: $description
  ) {
    id
    modify_time
    create_time
    allowed_payment_source
    applicable_type
    is_publish
    unit_name
    description
    name
  }
}
    `;
export const AdminUpdateComponentDocument = `
    mutation AdminUpdateComponent($componentId: String!, $name: String, $description: String, $isPublish: Boolean, $unitName: String, $applicableType: ApplicableType, $allowedPaymentSource: [AllowedPaymentSource!]) {
  updateComponent(
    component_id: $componentId
    name: $name
    description: $description
    is_publish: $isPublish
    unit_name: $unitName
    applicable_type: $applicableType
    allowed_payment_source: $allowedPaymentSource
  )
}
    `;
export const AdminToggleComponentPublishDocument = `
    mutation AdminToggleComponentPublish($componentId: String!, $isPublish: Boolean) {
  updateComponent(component_id: $componentId, is_publish: $isPublish)
}
    `;
export const AdminDeleteComponentDocument = `
    mutation AdminDeleteComponent($componentId: String!) {
  deleteComponent(component_id: $componentId)
}
    `;
export const AdminCreateComponentPlanDocument = `
    mutation AdminCreateComponentPlan($componentId: String!, $name: String!, $duration: Float!, $durationUnit: DurationUnit!, $basicQuantity: Float!, $trialDuration: Float!, $trialDurationUnit: DurationUnit!, $isDefault: Boolean!, $isPublish: Boolean!, $isComplimentary: Boolean!, $isAutoApprove: Boolean!, $description: String) {
  createComponentPlan(
    component_id: $componentId
    name: $name
    duration: $duration
    duration_unit: $durationUnit
    basic_quantity: $basicQuantity
    trial_duration: $trialDuration
    trial_duration_unit: $trialDurationUnit
    is_default: $isDefault
    is_publish: $isPublish
    is_complimentary: $isComplimentary
    is_auto_approve: $isAutoApprove
    description: $description
  ) {
    id
    name
    description
    duration
    duration_unit
    basic_quantity
    trial_duration
    trial_duration_unit
    is_default
    is_publish
    is_complimentary
    is_auto_approve
    create_time
    modify_time
  }
}
    `;
export const AdminUpdateComponentPlanDocument = `
    mutation AdminUpdateComponentPlan($componentPlanId: String!, $name: String, $description: String, $duration: Float, $durationUnit: DurationUnit, $basicQuantity: Float, $trialDuration: Float, $trialDurationUnit: DurationUnit, $isDefault: Boolean, $isPublish: Boolean, $isComplimentary: Boolean, $isAutoApprove: Boolean) {
  updateComponentPlan(
    component_plan_id: $componentPlanId
    name: $name
    description: $description
    duration: $duration
    duration_unit: $durationUnit
    basic_quantity: $basicQuantity
    trial_duration: $trialDuration
    trial_duration_unit: $trialDurationUnit
    is_default: $isDefault
    is_publish: $isPublish
    is_complimentary: $isComplimentary
    is_auto_approve: $isAutoApprove
  )
}
    `;
export const AdminToggleComponentPlanPublishDocument = `
    mutation AdminToggleComponentPlanPublish($componentPlanId: String!, $isPublish: Boolean) {
  updateComponentPlan(component_plan_id: $componentPlanId, is_publish: $isPublish)
}
    `;
export const AdminDeleteComponentPlanDocument = `
    mutation AdminDeleteComponentPlan($componentPlanId: String!) {
  deleteComponentPlan(component_plan_id: $componentPlanId)
}
    `;
export const ChargifyCreatePaymentProfileDocument = `
    mutation ChargifyCreatePaymentProfile($token: String!) {
  chargify_create_payment_profile(token: $token) {
    id
    first_name
    last_name
    customer_id
    billing_address
    billing_address_2
    billing_city
    billing_state
    billing_zip
    billing_country
    masked_card_number
    payment_type
    card_type
    expiration_month
    expiration_year
    gateway_handle
  }
}
    `;
export const ChargifyUpdatePaymentProfileDocument = `
    mutation ChargifyUpdatePaymentProfile($token: String!, $chargifyUpdatePaymentProfileId: Float!) {
  chargify_update_payment_profile(
    token: $token
    id: $chargifyUpdatePaymentProfileId
  ) {
    id
    first_name
    last_name
    customer_id
    billing_address
    billing_address_2
    billing_city
    billing_state
    billing_zip
    billing_country
    masked_card_number
    payment_type
    card_type
    expiration_month
    expiration_year
    gateway_handle
  }
}
    `;
export const ChargifyDeletePaymentProfileDocument = `
    mutation ChargifyDeletePaymentProfile($chargifyDeletePaymentProfileId: Float!) {
  chargify_delete_payment_profile(id: $chargifyDeletePaymentProfileId)
}
    `;
export const ChargifySubscribeDocument = `
    mutation ChargifySubscribe($subscriptionClientType: SubscriptionClientType!, $paymentType: PaymentType!, $productPlanId: String, $componentPlans: [ComponentPlanBuyingDetail!], $token: String, $paymentId: Float, $couponCodes: [String!]) {
  chargify_subscribe(
    subscription_client_type: $subscriptionClientType
    payment_type: $paymentType
    product_plan_id: $productPlanId
    component_plans: $componentPlans
    token: $token
    payment_id: $paymentId
    coupon_codes: $couponCodes
  ) {
    description
    id
    name
    next_billing_time
    payment_event_id
    payment_source
    product_plan_id
    status
    trial_end_time
    trial_start_time
    user_id
  }
}
    `;
export const GoTrailDocument = `
    mutation GoTrail($subscriptionClientType: SubscriptionClientType!, $productPlanId: String, $token: String, $paymentType: PaymentType!) {
  chargify_subscribe(
    subscription_client_type: $subscriptionClientType
    product_plan_id: $productPlanId
    token: $token
    payment_type: $paymentType
  ) {
    id
    name
  }
}
    `;
export const UnsubscribeDocument = `
    mutation Unsubscribe($subscriptionIds: [String!]!, $subscriptionClientType: SubscriptionClientType!) {
  chargify_unsubscribe(
    subscription_ids: $subscriptionIds
    subscription_client_type: $subscriptionClientType
  )
}
    `;
export const ChargifyResumeDocument = `
    mutation ChargifyResume($subscriptionClientType: SubscriptionClientType!, $subscriptionId: String!) {
  chargify_resume(
    subscription_client_type: $subscriptionClientType
    subscription_id: $subscriptionId
  )
}
    `;
export const ChargifyMigrateDocument = `
    mutation ChargifyMigrate($subscriptionClientType: SubscriptionClientType!, $productPlanId: String!, $subscriptionId: String!) {
  chargify_migrate(
    subscription_client_type: $subscriptionClientType
    product_plan_id: $productPlanId
    subscription_id: $subscriptionId
  )
}
    `;
export const ChargifyAddCouponDocument = `
    mutation ChargifyAddCoupon($subscriptionClientType: SubscriptionClientType!, $subscriptionId: String!, $couponCodes: [String!]!) {
  chargify_add_coupon(
    subscription_client_type: $subscriptionClientType
    subscription_id: $subscriptionId
    coupon_codes: $couponCodes
  )
}
    `;
export const ChargifyRemoveCouponDocument = `
    mutation ChargifyRemoveCoupon($couponCode: String!, $subscriptionId: String!, $subscriptionClientType: SubscriptionClientType!) {
  chargify_remove_coupon(
    coupon_code: $couponCode
    subscription_id: $subscriptionId
    subscription_client_type: $subscriptionClientType
  )
}
    `;
export const AdminProductFamiliesDocument = `
    query AdminProductFamilies {
  adminProductFamilies {
    id
    name
    description
    display_icon_url
    create_time
    modify_time
    products {
      id
      name
      description
      is_publish
      applicable_type
      allowed_payment_source
      create_time
      modify_time
      plans {
        id
        name
        description
        duration
        duration_unit
        trial_duration
        trial_duration_unit
        is_default
        is_publish
        is_complimentary
        is_auto_approve
        create_time
        modify_time
      }
    }
    components {
      id
      name
      description
      unit_name
      is_publish
      applicable_type
      allowed_payment_source
      create_time
      modify_time
      plans {
        id
        name
        description
        duration
        duration_unit
        basic_quantity
        trial_duration
        trial_duration_unit
        is_default
        is_publish
        is_complimentary
        is_auto_approve
        create_time
        modify_time
      }
    }
  }
}
    `;
export const PaymentProfileDocument = `
    query PaymentProfile {
  chargify_payment_profile {
    id
    first_name
    last_name
    customer_id
    billing_address
    billing_address_2
    billing_city
    billing_state
    billing_zip
    billing_country
    masked_card_number
    payment_type
    card_type
    expiration_month
    expiration_year
    gateway_handle
  }
}
    `;
export const ProductFamiliesDocument = `
    query ProductFamilies {
  productFamilies {
    id
    name
    description
    display_icon_url
    create_time
    modify_time
    products {
      id
      name
      description
      is_publish
      applicable_type
      allowed_payment_source
      create_time
      modify_time
      plans {
        id
        name
        description
        duration
        duration_unit
        trial_duration
        trial_duration_unit
        is_default
        is_publish
        is_complimentary
        is_auto_approve
        create_time
        modify_time
      }
    }
    components {
      id
      name
      description
      unit_name
      is_publish
      applicable_type
      allowed_payment_source
      create_time
      modify_time
      plans {
        id
        name
        description
        duration
        duration_unit
        basic_quantity
        trial_duration
        trial_duration_unit
        is_default
        is_publish
        is_complimentary
        is_auto_approve
        create_time
        modify_time
      }
    }
  }
}
    `;
export const ProductFamiliesListDocument = `
    query ProductFamiliesList {
  productFamilies {
    id
    name
    display_icon_url
  }
}
    `;
export const UserSubscriptionsDocument = `
    query UserSubscriptions($familyNames: [String!], $status: [SubscriptionStatus!]) {
  userSubscriptions(familyNames: $familyNames, status: $status) {
    id
    user_id
    product_plan_id
    name
    description
    status
    next_billing_time
    trial_start_time
    trial_end_time
    payment_source
    payment_event_id
  }
}
    `;
export const UserSubscriptionsRealTimeDataDocument = `
    query UserSubscriptionsRealTimeData($familyNames: [String!], $status: [SubscriptionStatus!]) {
  userSubscriptions(familyNames: $familyNames, status: $status) {
    id
    vendorRealTimeData {
      id
      nextProductPlanHandle
      nextProductPlanName
      couponUseCount
      couponUsesAllowed
      couponCodeApplied
    }
  }
}
    `;
export const UserInvoicesDocument = `
    query UserInvoices($productFamilyId: String!, $subscriptionId: String, $page: Float, $pageSize: Float, $direction: Directions) {
  chargify_invoices(
    product_family_id: $productFamilyId
    subscription_id: $subscriptionId
    page: $page
    page_size: $pageSize
    direction: $direction
  ) {
    current_page
    page_size
    total_invoice_count
    total_pages
    invoices {
      number
      pdf_url
      total_amount
      paid_date
      line_items {
        period_range_end
        period_range_start
        description
      }
    }
  }
}
    `;
export const ChargifyPreviewDocument = `
    query ChargifyPreview($subscriptionClientType: SubscriptionClientType!, $subscriptionId: String, $productPlanId: String, $couponCodes: [String!], $creditCardAttributes: PreviewCreditCardDetail, $componentPlans: [ComponentPlanBuyingDetail!]) {
  chargify_preview(
    subscription_client_type: $subscriptionClientType
    subscription_id: $subscriptionId
    product_plan_id: $productPlanId
    coupon_codes: $couponCodes
    credit_card_attributes: $creditCardAttributes
    component_plans: $componentPlans
  ) {
    start_time
    end_time
    charge
    credit_applied
    payment_due
    prorated_adjustment
    current_billing_manifest {
      total
      total_discount
      total_tax
      subtotal
      existing_balance
    }
  }
}
    `;
export const ChargifyPriceDocument = `
    query ChargifyPrice($subscriptionClientType: SubscriptionClientType!, $productFamilyId: String!) {
  chargify_price(
    subscription_client_type: $subscriptionClientType
    product_family_id: $productFamilyId
  ) {
    plan_id
    price
  }
}
    `;

const injectedRtkApi = VsSubscriptionGraphqlApiBase.injectEndpoints({
  endpoints: build => ({
    AdminCreateProductFamilies: build.mutation<
      AdminCreateProductFamiliesMutation,
      AdminCreateProductFamiliesMutationVariables
    >({
      query: variables => ({
        document: AdminCreateProductFamiliesDocument,
        variables,
      }),
    }),
    AdminUpdateProductFamily: build.mutation<
      AdminUpdateProductFamilyMutation,
      AdminUpdateProductFamilyMutationVariables
    >({
      query: variables => ({
        document: AdminUpdateProductFamilyDocument,
        variables,
      }),
    }),
    AdminDeleteProductFamilies: build.mutation<
      AdminDeleteProductFamiliesMutation,
      AdminDeleteProductFamiliesMutationVariables
    >({
      query: variables => ({
        document: AdminDeleteProductFamiliesDocument,
        variables,
      }),
    }),
    AdminCreateProduct: build.mutation<
      AdminCreateProductMutation,
      AdminCreateProductMutationVariables
    >({
      query: variables => ({ document: AdminCreateProductDocument, variables }),
    }),
    AdminUpdateProduct: build.mutation<
      AdminUpdateProductMutation,
      AdminUpdateProductMutationVariables
    >({
      query: variables => ({ document: AdminUpdateProductDocument, variables }),
    }),
    AdminToggleProductPublish: build.mutation<
      AdminToggleProductPublishMutation,
      AdminToggleProductPublishMutationVariables
    >({
      query: variables => ({
        document: AdminToggleProductPublishDocument,
        variables,
      }),
    }),
    AdminDeleteProduct: build.mutation<
      AdminDeleteProductMutation,
      AdminDeleteProductMutationVariables
    >({
      query: variables => ({ document: AdminDeleteProductDocument, variables }),
    }),
    AdminCreateProductPlan: build.mutation<
      AdminCreateProductPlanMutation,
      AdminCreateProductPlanMutationVariables
    >({
      query: variables => ({
        document: AdminCreateProductPlanDocument,
        variables,
      }),
    }),
    AdminUpdateProductPlan: build.mutation<
      AdminUpdateProductPlanMutation,
      AdminUpdateProductPlanMutationVariables
    >({
      query: variables => ({
        document: AdminUpdateProductPlanDocument,
        variables,
      }),
    }),
    AdminToggleProductPlanPublish: build.mutation<
      AdminToggleProductPlanPublishMutation,
      AdminToggleProductPlanPublishMutationVariables
    >({
      query: variables => ({
        document: AdminToggleProductPlanPublishDocument,
        variables,
      }),
    }),
    AdminDeleteProductPlan: build.mutation<
      AdminDeleteProductPlanMutation,
      AdminDeleteProductPlanMutationVariables
    >({
      query: variables => ({
        document: AdminDeleteProductPlanDocument,
        variables,
      }),
    }),
    AdminCreateComponent: build.mutation<
      AdminCreateComponentMutation,
      AdminCreateComponentMutationVariables
    >({
      query: variables => ({
        document: AdminCreateComponentDocument,
        variables,
      }),
    }),
    AdminUpdateComponent: build.mutation<
      AdminUpdateComponentMutation,
      AdminUpdateComponentMutationVariables
    >({
      query: variables => ({
        document: AdminUpdateComponentDocument,
        variables,
      }),
    }),
    AdminToggleComponentPublish: build.mutation<
      AdminToggleComponentPublishMutation,
      AdminToggleComponentPublishMutationVariables
    >({
      query: variables => ({
        document: AdminToggleComponentPublishDocument,
        variables,
      }),
    }),
    AdminDeleteComponent: build.mutation<
      AdminDeleteComponentMutation,
      AdminDeleteComponentMutationVariables
    >({
      query: variables => ({
        document: AdminDeleteComponentDocument,
        variables,
      }),
    }),
    AdminCreateComponentPlan: build.mutation<
      AdminCreateComponentPlanMutation,
      AdminCreateComponentPlanMutationVariables
    >({
      query: variables => ({
        document: AdminCreateComponentPlanDocument,
        variables,
      }),
    }),
    AdminUpdateComponentPlan: build.mutation<
      AdminUpdateComponentPlanMutation,
      AdminUpdateComponentPlanMutationVariables
    >({
      query: variables => ({
        document: AdminUpdateComponentPlanDocument,
        variables,
      }),
    }),
    AdminToggleComponentPlanPublish: build.mutation<
      AdminToggleComponentPlanPublishMutation,
      AdminToggleComponentPlanPublishMutationVariables
    >({
      query: variables => ({
        document: AdminToggleComponentPlanPublishDocument,
        variables,
      }),
    }),
    AdminDeleteComponentPlan: build.mutation<
      AdminDeleteComponentPlanMutation,
      AdminDeleteComponentPlanMutationVariables
    >({
      query: variables => ({
        document: AdminDeleteComponentPlanDocument,
        variables,
      }),
    }),
    ChargifyCreatePaymentProfile: build.mutation<
      ChargifyCreatePaymentProfileMutation,
      ChargifyCreatePaymentProfileMutationVariables
    >({
      query: variables => ({
        document: ChargifyCreatePaymentProfileDocument,
        variables,
      }),
    }),
    ChargifyUpdatePaymentProfile: build.mutation<
      ChargifyUpdatePaymentProfileMutation,
      ChargifyUpdatePaymentProfileMutationVariables
    >({
      query: variables => ({
        document: ChargifyUpdatePaymentProfileDocument,
        variables,
      }),
    }),
    ChargifyDeletePaymentProfile: build.mutation<
      ChargifyDeletePaymentProfileMutation,
      ChargifyDeletePaymentProfileMutationVariables
    >({
      query: variables => ({
        document: ChargifyDeletePaymentProfileDocument,
        variables,
      }),
    }),
    ChargifySubscribe: build.mutation<
      ChargifySubscribeMutation,
      ChargifySubscribeMutationVariables
    >({
      query: variables => ({ document: ChargifySubscribeDocument, variables }),
    }),
    GoTrail: build.mutation<GoTrailMutation, GoTrailMutationVariables>({
      query: variables => ({ document: GoTrailDocument, variables }),
    }),
    Unsubscribe: build.mutation<
      UnsubscribeMutation,
      UnsubscribeMutationVariables
    >({
      query: variables => ({ document: UnsubscribeDocument, variables }),
    }),
    ChargifyResume: build.mutation<
      ChargifyResumeMutation,
      ChargifyResumeMutationVariables
    >({
      query: variables => ({ document: ChargifyResumeDocument, variables }),
    }),
    ChargifyMigrate: build.mutation<
      ChargifyMigrateMutation,
      ChargifyMigrateMutationVariables
    >({
      query: variables => ({ document: ChargifyMigrateDocument, variables }),
    }),
    ChargifyAddCoupon: build.mutation<
      ChargifyAddCouponMutation,
      ChargifyAddCouponMutationVariables
    >({
      query: variables => ({ document: ChargifyAddCouponDocument, variables }),
    }),
    ChargifyRemoveCoupon: build.mutation<
      ChargifyRemoveCouponMutation,
      ChargifyRemoveCouponMutationVariables
    >({
      query: variables => ({
        document: ChargifyRemoveCouponDocument,
        variables,
      }),
    }),
    AdminProductFamilies: build.query<
      AdminProductFamiliesQuery,
      AdminProductFamiliesQueryVariables | void
    >({
      query: variables => ({
        document: AdminProductFamiliesDocument,
        variables,
      }),
    }),
    PaymentProfile: build.query<
      PaymentProfileQuery,
      PaymentProfileQueryVariables | void
    >({
      query: variables => ({ document: PaymentProfileDocument, variables }),
    }),
    ProductFamilies: build.query<
      ProductFamiliesQuery,
      ProductFamiliesQueryVariables | void
    >({
      query: variables => ({ document: ProductFamiliesDocument, variables }),
    }),
    ProductFamiliesList: build.query<
      ProductFamiliesListQuery,
      ProductFamiliesListQueryVariables | void
    >({
      query: variables => ({
        document: ProductFamiliesListDocument,
        variables,
      }),
    }),
    UserSubscriptions: build.query<
      UserSubscriptionsQuery,
      UserSubscriptionsQueryVariables | void
    >({
      query: variables => ({ document: UserSubscriptionsDocument, variables }),
    }),
    UserSubscriptionsRealTimeData: build.query<
      UserSubscriptionsRealTimeDataQuery,
      UserSubscriptionsRealTimeDataQueryVariables | void
    >({
      query: variables => ({
        document: UserSubscriptionsRealTimeDataDocument,
        variables,
      }),
    }),
    UserInvoices: build.query<UserInvoicesQuery, UserInvoicesQueryVariables>({
      query: variables => ({ document: UserInvoicesDocument, variables }),
    }),
    ChargifyPreview: build.query<
      ChargifyPreviewQuery,
      ChargifyPreviewQueryVariables
    >({
      query: variables => ({ document: ChargifyPreviewDocument, variables }),
    }),
    ChargifyPrice: build.query<ChargifyPriceQuery, ChargifyPriceQueryVariables>(
      {
        query: variables => ({ document: ChargifyPriceDocument, variables }),
      }
    ),
  }),
});

export { injectedRtkApi as api };
export const {
  useAdminCreateProductFamiliesMutation,
  useAdminUpdateProductFamilyMutation,
  useAdminDeleteProductFamiliesMutation,
  useAdminCreateProductMutation,
  useAdminUpdateProductMutation,
  useAdminToggleProductPublishMutation,
  useAdminDeleteProductMutation,
  useAdminCreateProductPlanMutation,
  useAdminUpdateProductPlanMutation,
  useAdminToggleProductPlanPublishMutation,
  useAdminDeleteProductPlanMutation,
  useAdminCreateComponentMutation,
  useAdminUpdateComponentMutation,
  useAdminToggleComponentPublishMutation,
  useAdminDeleteComponentMutation,
  useAdminCreateComponentPlanMutation,
  useAdminUpdateComponentPlanMutation,
  useAdminToggleComponentPlanPublishMutation,
  useAdminDeleteComponentPlanMutation,
  useChargifyCreatePaymentProfileMutation,
  useChargifyUpdatePaymentProfileMutation,
  useChargifyDeletePaymentProfileMutation,
  useChargifySubscribeMutation,
  useGoTrailMutation,
  useUnsubscribeMutation,
  useChargifyResumeMutation,
  useChargifyMigrateMutation,
  useChargifyAddCouponMutation,
  useChargifyRemoveCouponMutation,
  useAdminProductFamiliesQuery,
  useLazyAdminProductFamiliesQuery,
  usePaymentProfileQuery,
  useLazyPaymentProfileQuery,
  useProductFamiliesQuery,
  useLazyProductFamiliesQuery,
  useProductFamiliesListQuery,
  useLazyProductFamiliesListQuery,
  useUserSubscriptionsQuery,
  useLazyUserSubscriptionsQuery,
  useUserSubscriptionsRealTimeDataQuery,
  useLazyUserSubscriptionsRealTimeDataQuery,
  useUserInvoicesQuery,
  useLazyUserInvoicesQuery,
  useChargifyPreviewQuery,
  useLazyChargifyPreviewQuery,
  useChargifyPriceQuery,
  useLazyChargifyPriceQuery,
} = injectedRtkApi;
