import {
  InMemoryWebStorage,
  Log,
  OidcClientSettings,
  User,
  WebStorageStateStore,
} from 'oidc-client-ts';
import { useEffect, useRef, useState } from 'react';
import { AuthProvider } from 'react-oidc-context';

import { oidcStoragePrefix } from '../constants';

let store: Storage | undefined;
let storage: WebStorageStateStore | undefined;

type PropsProviderOidc = {
  config: OidcClientSettings;
  debugMode?: boolean;
  children: React.ReactNode;
};

export function ProviderOidc({
  config,
  children,
  debugMode,
}: PropsProviderOidc) {
  const [userStore, setUseStore] = useState<WebStorageStateStore | undefined>(
    undefined
  );
  const stateRef = useRef<{ enableOidcLogger: boolean }>({
    enableOidcLogger: false,
  });
  if (!stateRef.current.enableOidcLogger && debugMode) {
    Log.setLogger(console);
    Log.setLevel(Log.DEBUG);
    stateRef.current.enableOidcLogger = true;
  } else if (stateRef.current && !debugMode) {
    Log.reset();
    stateRef.current.enableOidcLogger = false;
  }

  useEffect(() => {
    function unregisterServiceWorker() {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistrations().then(registrations => {
          registrations.forEach(registration => {
            registration.unregister();
          });
        });
      }
    }

    // Unregister previously registered service worker
    // TODO: remove this later
    unregisterServiceWorker();
  }, []);

  useEffect(() => {
    store = new InMemoryWebStorage();

    storage = new WebStorageStateStore({
      prefix: oidcStoragePrefix,
      store,
    });

    setUseStore(storage);
  }, []);

  if (!userStore) return null;

  return (
    <AuthProvider
      {...config}
      userStore={userStore}
      automaticSilentRenew={false}
      loadUserInfo
      onSigninCallback={() => {
        // TODO: for deprecating localStorage, remove this later
        Object.keys(localStorage)
          .filter(key => key.startsWith('oidc.user'))
          .forEach(key => {
            localStorage.removeItem(key);
          });
      }}
    >
      {children}
    </AuthProvider>
  );
}

export async function getOidcStorageInfo({
  authority,
  client_id,
}: {
  authority: string;
  client_id: string;
}) {
  if (storage) {
    const oidcStorage = await storage?.get(`user:${authority}:${client_id}`);
    if (oidcStorage) {
      return User.fromStorageString(oidcStorage);
    }
  }
  return null;
}

export async function getAccessToken({
  authority,
  client_id,
}: {
  authority: string;
  client_id: string;
}) {
  const storageData = await getOidcStorageInfo({ authority, client_id });
  return storageData?.access_token ?? null;
}
