import { createApi } from '@reduxjs/toolkit/query/react';
import {
  ArgsInitSliceMeta,
  ReferenceOfGraphqlMeta,
  dynamicGraphqlBaseQuery,
} from '@vs/rtk/utils';
import { getEnvConfig } from '@vs/utils/envConfig';

const referenceOfMeta: ReferenceOfGraphqlMeta = {
  _baseUrl: '',
  _getTokenFunc: () => {
    return Promise.resolve('');
  },
};

export function initSliceMeta({
  deployEnv,
  getTokenFunc,
  oidcConfig,
}: ArgsInitSliceMeta) {
  referenceOfMeta._baseUrl = getEnvConfig({
    deployEnv,
  }).apiEndpoint.vsSubscription;
  referenceOfMeta._getTokenFunc = getTokenFunc as () => Promise<string | null>;
}

export const VsSubscriptionGraphqlApiBase = createApi({
  reducerPath: 'vsSubscriptionGraphqlApi',
  baseQuery: dynamicGraphqlBaseQuery(referenceOfMeta),
  endpoints: () => ({}),
  tagTypes: [],
});
