import { usePathMeta } from '@vs/shared-context/spa-path-config-provider';
import { PortalHeader, PortalHeaderProps } from '@vs/ui/portal/header';

/**
 * Show / hide portal header according to path config's meta
 *
 * @export
 * @param {PortalHeaderProps} props
 * @returns {ReactElement}
 */
export function PortalHeaderWithPathMeta({
  avatarMenu,
  ...restProps
}: PortalHeaderProps) {
  const pathMeta = usePathMeta();

  return (
    pathMeta.show.header && (
      <PortalHeader
        avatarMenu={pathMeta.show.avatar && avatarMenu}
        showMobileBurgerIcon={pathMeta.show.menu}
        mode={pathMeta.backGroundMode}
        {...restProps}
      />
    )
  );
}

export default PortalHeaderWithPathMeta;
