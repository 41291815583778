import {
  AccountApiControllerGetEntityInfoApiResponse,
  vsAccountApiInjected,
} from './vsAccountApiInjected';

export enum VsAccountSliceTags {
  ACCOUNT_ENTITY_INFO = 'ACCOUNT_ENTITY_INFO',
  ACCOUNT_SETTINGS = 'ACCOUNT_SETTINGS',
  AVATAR = 'AVATAR',
  MARKETING = 'MARKETING',
}

const vsAccountApiEnhanced = vsAccountApiInjected.enhanceEndpoints({
  addTagTypes: Object.values(VsAccountSliceTags),
  endpoints: {
    accountApiControllerGetEntityInfo: {
      providesTags: [VsAccountSliceTags.ACCOUNT_ENTITY_INFO],
      extraOptions: {
        fallbackResponseOfNotFound: {
          data: {
            id: '',
            name: '',
            generics_code: '',
            domain_type_code: '',
            area: '',
            create_time: '',
            modify_time: '',
            profile: {
              contact_email: '',
              contact_phone: '',
              zipcode: '',
              country_code: '',
              country: '',
              region: '',
              city: '',
              address: '',
            },
            domains: [],
            entities: [],
          },
        } as AccountApiControllerGetEntityInfoApiResponse,
      },
    },
    accountApiControllerFind: {
      providesTags: [VsAccountSliceTags.ACCOUNT_SETTINGS],
    },
    accountApiControllerUpdate: {
      invalidatesTags: [VsAccountSliceTags.ACCOUNT_SETTINGS],
    },
    avatarApiControllerGet: {
      providesTags: [VsAccountSliceTags.AVATAR],
    },
    avatarApiControllerUpdate: {
      invalidatesTags: [VsAccountSliceTags.AVATAR],
    },
    avatarApiControllerDelete: {
      invalidatesTags: [VsAccountSliceTags.AVATAR],
    },
    marketingApiControllerFind: {
      providesTags: [VsAccountSliceTags.MARKETING],
    },
    marketingApiControllerUpdate: {
      invalidatesTags: [VsAccountSliceTags.MARKETING],
    },
    accountApiControllerVerifyAccountEmail: {
      extraOptions: { isPublicEndpoint: true },
    },
    accountApiControllerDeleteAccountByHashId: {
      extraOptions: { isPublicEndpoint: true },
    },
  },
});

export { vsAccountApiEnhanced as vsAccountApiSlice };
export * from './vsAccountApiInjected';

type supportedSize = { width: 150; height: 150 } | { width: 48; height: 48 };
export const useAvatarUrl = (
  size: supportedSize,
  accountId: string
): string => {
  const { data: avatar } = vsAccountApiEnhanced.useAvatarApiControllerGetQuery(
    {
      accountId,
    },
    { skip: !accountId }
  );

  // example avatar_url ==> https://assets.dev.viewsonic.cloud/avatar/438f9ff3-ed9a-4332-b30c-7da1a24c5582_48x48.jpeg
  if (avatar?.avatar_url) {
    return `${avatar?.avatar_url.replace(
      '_48x48',
      `_${size.width}x${size.height}`
    )}`;
  }
  return '';
};
