import { ReactElement, ReactNode, useMemo, useRef } from 'react';

import { context, defaultTableActionState } from './context';
import { Handlers, TablesActionState } from './type';

export type TableActionStateProviderProps = {
  /** valid react children */
  children: ReactNode;
};
/**
 * Provider of table action states
 *
 * @export
 * @param {TableActionStateProviderProps} props
 * @returns {ReactElement}
 */
export function TableActionStateProvider(
  props: TableActionStateProviderProps
): ReactElement {
  const { children } = props;
  const tableActionStateRef = useRef<TablesActionState>({});

  const handlers = useMemo<Handlers>(
    () => ({
      getTableActionState: key =>
        !key
          ? defaultTableActionState
          : tableActionStateRef.current[key] ?? defaultTableActionState,
      updateTableActionState: (key, nextState) => {
        tableActionStateRef.current[key] = nextState;
      },
    }),
    []
  );

  return <context.Provider value={handlers}>{children}</context.Provider>;
}
