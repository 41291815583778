import { VsIcon, genVsIconBaseProps } from '../shared';

export const ClassSwiftIcon: VsIcon = props => {
  return (
    <svg {...genVsIconBaseProps(props)} viewBox="0 0 34 27">
      <path
        d="M27.9006 8.60343C26.4595 9.57193 24.641 10.393 22.3499 10.9055L18.3712 16.5402C18.3712 16.5402 18.3671 16.5484 18.363 16.554L15.62 20.4376C15.5925 20.4776 15.5649 20.5134 15.5387 20.5547L14.9877 21.3358L11.6523 26.0571H18.3974C22.6737 26.0571 26.68 23.9892 29.1308 20.5175C31.9564 16.5168 31.01 11.5406 27.9006 8.60481V8.60205V8.60343Z"
        fill="#DB1F28"
      />
      <path
        d="M18.0146 5.85785C18.0146 5.85785 18.0174 5.85233 18.0201 5.84958C18.8123 4.72678 19.4984 3.7569 20.0674 2.95096L21.9878 0.231445H15.2428C10.9665 0.231445 6.96021 2.29933 4.50934 5.77105C1.68374 9.7718 2.6302 14.7479 5.7396 17.6838C7.18064 16.7153 8.99916 15.8942 11.2902 15.3817L18.0146 5.85922V5.85785Z"
        fill="#48C2C7"
      />
      <path
        d="M18.0157 5.85785C16.8971 7.45319 16.8833 11.4691 16.8833 11.4691C18.9567 11.4691 20.7642 11.2597 22.3499 10.9042C24.6423 10.3918 26.4609 9.57066 27.9005 8.60216C32.8532 5.27371 33.3588 0.231445 33.3588 0.231445H21.9889C21.9889 0.231445 21.307 1.19581 20.0671 2.95234C19.4981 3.7569 18.812 4.72816 18.0199 5.85096C18.0199 5.85371 18.0171 5.85647 18.0144 5.85922L18.0157 5.85785Z"
        fill="#F3BA1A"
      />
      <path
        d="M15.6202 20.4349C16.7444 18.8437 16.7582 14.8154 16.7582 14.8154C14.6848 14.8154 12.8773 15.0248 11.2916 15.3803C8.99914 15.8928 7.18062 16.7139 5.74095 17.6824C0.788232 21.0122 0.28125 26.0545 0.28125 26.0545H11.6512C11.6512 26.0545 13.0688 24.0486 15.5389 20.552C15.5665 20.5135 15.5927 20.4763 15.6202 20.4349Z"
        fill="#2E3283"
      />
    </svg>
  );
};
