import Lottie from 'react-lottie-player';

import animation from './loading.json';

export type PropsPortalUiLottieLoading = {
  size?: number;
};

export function PortalUiLottieLoading({ size }: PropsPortalUiLottieLoading) {
  return (
    <Lottie
      animationData={animation}
      play
      style={{ width: size, height: size }}
    />
  );
}

export default PortalUiLottieLoading;
