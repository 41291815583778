import { DefinitionsFromApi, OverrideResultType } from '@reduxjs/toolkit/query';

import {
  GeolocationControllerGetCountriesApiResponse,
  GeolocationControllerGetCountriesStatesApiResponse,
  GeolocationControllerGetStatesCitiesApiResponse,
  LanguageControllerGetLanguagesApiResponse,
  vsCommonApiInjected,
} from './vsCommonApiInjected';

type Definitions = DefinitionsFromApi<typeof vsCommonApiInjected>;

type EnhancedDefinitions = Omit<
  Definitions,
  | 'languageControllerGetLanguages'
  | 'geolocationControllerGetCountries'
  | 'geolocationControllerGetCountriesStates'
  | 'geolocationControllerGetStatesCities'
> & {
  languageControllerGetLanguages: OverrideResultType<
    Definitions['languageControllerGetLanguages'],
    ReturnType<typeof transformLanguageResponse>
  >;
  geolocationControllerGetCountries: OverrideResultType<
    Definitions['geolocationControllerGetCountries'],
    ReturnType<typeof transformContriesResponse>
  >;
  geolocationControllerGetCountriesStates: OverrideResultType<
    Definitions['geolocationControllerGetCountriesStates'],
    ReturnType<typeof transformStateResponse>
  >;
  geolocationControllerGetStatesCities: OverrideResultType<
    Definitions['geolocationControllerGetStatesCities'],
    ReturnType<typeof transformCitiesResponse>
  >;
};

enum VsCommonSliceTags {
  LANG = 'LANG',
  VS_AREA = 'VS_AREA',
  SHARD_REGION = 'SHARD_REGION',
}

const vsCommonApiEnhanced = vsCommonApiInjected.enhanceEndpoints<
  VsCommonSliceTags,
  EnhancedDefinitions
>({
  addTagTypes: Object.values(VsCommonSliceTags),
  endpoints: {
    managementControllerGetLanguages: {
      providesTags: [VsCommonSliceTags.LANG],
    },
    managementControllerCreateLanguages: {
      invalidatesTags: [VsCommonSliceTags.LANG],
    },
    managementControllerUpdateLanguages: {
      invalidatesTags: [VsCommonSliceTags.LANG],
    },
    managementControllerDeleteLanguages: {
      invalidatesTags: [VsCommonSliceTags.LANG],
    },
    languageControllerGetLanguages: {
      transformResponse: transformLanguageResponse,
      providesTags: [VsCommonSliceTags.LANG],
      extraOptions: {
        isPublicEndpoint: true,
      },
    },
    geolocationControllerGetCountries: {
      providesTags: [VsCommonSliceTags.VS_AREA, VsCommonSliceTags.SHARD_REGION],
      transformResponse: transformContriesResponse,
      extraOptions: {
        isPublicEndpoint: true,
      },
    },
    geolocationControllerGetCountriesStates: {
      transformResponse: transformStateResponse,
      extraOptions: {
        isPublicEndpoint: true,
        fallbackResponseOfNotFound:
          [] as GeolocationControllerGetCountriesStatesApiResponse,
      },
    },
    geolocationControllerGetStatesCities: {
      transformResponse: transformCitiesResponse,
      extraOptions: {
        isPublicEndpoint: true,
        fallbackResponseOfNotFound:
          [] as GeolocationControllerGetStatesCitiesApiResponse,
      },
    },
    managementControllerUpdateCountry: {
      invalidatesTags: [VsCommonSliceTags.VS_AREA],
    },
    managementControllerUpdateCountryShardRegion: {
      invalidatesTags: [VsCommonSliceTags.SHARD_REGION],
    },
  },
});

function transformLanguageResponse(
  resp: LanguageControllerGetLanguagesApiResponse
) {
  return resp.map(({ name, code }) => ({ label: name, value: code }));
}
function transformContriesResponse(
  resp: GeolocationControllerGetCountriesApiResponse
) {
  return resp.map(({ id, name, iso2, vs_area, shard_region }) => ({
    id,
    vs_area,
    shard_region,
    label: name ?? '',
    value: iso2 ?? '',
  }));
}
function transformStateResponse(
  resp: GeolocationControllerGetCountriesStatesApiResponse
) {
  return resp
    .sort((a, b) => (a?.name ?? '').localeCompare(b?.name ?? ''))
    .map(({ id, name, iso2 }) => ({
      id,
      label: name ?? '',
      value: name ?? '',
      iso2: iso2 ?? '',
    }));
}
function transformCitiesResponse(
  resp: GeolocationControllerGetStatesCitiesApiResponse
) {
  return resp.map(({ id, name }) => ({
    id,
    label: name ?? '',
    value: name ?? '',
  }));
}

export { vsCommonApiEnhanced as vsCommonApiSlice };
export * from './vsCommonApiInjected';
/* If you transform response to another type,
   you have to re-export it's hook from enhanced slice to get correct type definition
   (No related issues found on github) */
export const {
  useLanguageControllerGetLanguagesQuery:
    useLanguageControllerTransformedGetLanguagesQuery,
  useGeolocationControllerGetCountriesQuery:
    useGeolocationControllerTransformedGetCountriesQuery,
  useGeolocationControllerGetCountriesStatesQuery:
    useGeolocationControllerTransformedGetCountriesStatesQuery,
  useGeolocationControllerGetStatesCitiesQuery:
    useGeolocationControllerTransformedGetStatesCitiesQuery,
} = vsCommonApiEnhanced;

type Args = {
  countryCode?: string;
  stateName?: string;
};

type Options = { skip?: boolean };

export const useCountryStateCityFilterList = (
  { countryCode, stateName }: Args,
  { skip }: Options = { skip: false }
) => {
  const {
    data: countryList = [],
    isLoading: isLoadingContries,
    isFetching: isFetchingContries,
  } = useGeolocationControllerTransformedGetCountriesQuery(undefined, { skip });
  const {
    data: stateList = [],
    isLoading: isLoadingStates,
    isFetching: isFetchingStates,
    isError: isGetStateError,
  } = useGeolocationControllerTransformedGetCountriesStatesQuery(
    { ciso: (countryCode ?? '').toLowerCase() },
    { skip: skip || !countryCode }
  );
  const stateIso = stateList.find(s => s.value === stateName)?.iso2;
  const {
    data: cityList = [],
    isLoading: isLoadingCities,
    isFetching: isFetchingCities,
    isError: isGetCityError,
  } = useGeolocationControllerTransformedGetStatesCitiesQuery(
    {
      ciso: (countryCode ?? '').toLowerCase(),
      siso: (stateIso ?? '').toLowerCase(),
    },
    { skip: skip || !countryCode || !stateIso }
  );

  return {
    countryList,
    isLoadingContries,
    isFetchingContries,
    stateList: isGetStateError ? [] : stateList,
    isLoadingStates,
    isFetchingStates,
    cityList: isGetCityError ? [] : cityList,
    isLoadingCities,
    isFetchingCities,
  };
};
