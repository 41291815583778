import { useContext, useMemo } from 'react';

import { context } from '../spa-path-config-provider';
import { flattenAppPathsAndView } from '../utils';

/**
 * Return string array includes all public paths (no authentication needed)
 *
 * @export
 * @returns {string[]}
 */
export function useAuthOmitPaths(): string[] {
  const pathsConfig = useContext(context);
  return useMemo(
    () =>
      flattenAppPathsAndView(pathsConfig)
        .filter(p => p.meta?.isPublicPath)
        .map(p => p.path) as string[],
    [pathsConfig]
  );
}
