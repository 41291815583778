import { VsIcon, genVsIconBaseProps } from '../shared';

export const RedeemPlus: VsIcon = props => {
  return (
    <svg {...genVsIconBaseProps(props)} viewBox="0 0 18 18">
      <path
        id="Union"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 3C2.40326 3 1.83097 3.23705 1.40901 3.65901C0.987053 4.08097 0.75 4.65326 0.75 5.25V6.75C0.75 7.16421 1.08579 7.5 1.5 7.5C1.89782 7.5 2.27936 7.65804 2.56066 7.93934C2.84196 8.22064 3 8.60217 3 9C3 9.39783 2.84196 9.77936 2.56066 10.0607C2.27936 10.342 1.89782 10.5 1.5 10.5C1.08579 10.5 0.75 10.8358 0.75 11.25V12.75C0.75 13.3467 0.987053 13.919 1.40901 14.341C1.83097 14.7629 2.40326 15 3 15H15C15.5967 15 16.169 14.7629 16.591 14.341C17.0129 13.919 17.25 13.3467 17.25 12.75V11.25C17.25 10.8358 16.9142 10.5 16.5 10.5C16.1022 10.5 15.7206 10.342 15.4393 10.0607C15.158 9.77936 15 9.39783 15 9C15 8.60217 15.158 8.22064 15.4393 7.93934C15.7206 7.65804 16.1022 7.5 16.5 7.5C16.9142 7.5 17.25 7.16421 17.25 6.75V5.25C17.25 4.65326 17.0129 4.08097 16.591 3.65901C16.169 3.23705 15.5967 3 15 3H3ZM2.46967 4.71967C2.61032 4.57902 2.80109 4.5 3 4.5H15C15.1989 4.5 15.3897 4.57902 15.5303 4.71967C15.671 4.86032 15.75 5.05109 15.75 5.25V6.09525C15.2355 6.22812 14.7608 6.49652 14.3787 6.87868C13.8161 7.44129 13.5 8.20435 13.5 9C13.5 9.79565 13.8161 10.5587 14.3787 11.1213C14.7608 11.5035 15.2355 11.7719 15.75 11.9047V12.75C15.75 12.9489 15.671 13.1397 15.5303 13.2803C15.3897 13.421 15.1989 13.5 15 13.5H3C2.80109 13.5 2.61032 13.421 2.46967 13.2803C2.32902 13.1397 2.25 12.9489 2.25 12.75V11.9047C2.76452 11.7719 3.23916 11.5035 3.62132 11.1213C4.18393 10.5587 4.5 9.79565 4.5 9C4.5 8.20435 4.18393 7.44129 3.62132 6.87868C3.23916 6.49652 2.76452 6.22812 2.25 6.09525V5.25C2.25 5.05109 2.32902 4.86032 2.46967 4.71967ZM9.75 9.75H11.25C11.6625 9.75 12 9.4125 12 9C12 8.5875 11.6625 8.25 11.25 8.25H9.75V6.75C9.75 6.3375 9.4125 6 9 6C8.5875 6 8.25 6.3375 8.25 6.75V8.25H6.75C6.3375 8.25 6 8.5875 6 9C6 9.4125 6.3375 9.75 6.75 9.75H8.25V11.25C8.25 11.6625 8.5875 12 9 12C9.4125 12 9.75 11.6625 9.75 11.25V9.75Z"
      />
    </svg>
  );
};
