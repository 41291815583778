import {
  MenuItemInterface,
  PortalMenu,
  UiPortalMenuProps,
} from '@vs/ui/portal/menu';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { useParentMenuItemOfMatchedChild } from './hooks';
import { MenuMeta, context } from './spa-path-config-provider';

type PropsSpaMenuByPathsConfig = {
  expand?: boolean;
  whiteListPolicies?: string[];
} & Pick<UiPortalMenuProps, 'onClick'>;

export function SpaMenuByPathsConfig({
  expand = false,
  onClick,
  whiteListPolicies = [],
}: PropsSpaMenuByPathsConfig) {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const pathsConfig = useContext(context);
  const menuItemPaths = pathsConfig.filter(
    p => p.menuMeta !== undefined && !p.hide
  );
  menuItemPaths.sort(
    (a, b) => (a.menuMeta?.order ?? 0) - (b.menuMeta?.order ?? 0)
  );
  const menuItems = menuItemPaths.map((m): MenuItemInterface => {
    const menuMeta = m.menuMeta as MenuMeta;
    const disabled =
      whiteListPolicies.length > 0 &&
      m.menuMeta?.policyString !== undefined &&
      !whiteListPolicies.includes(m.menuMeta.policyString);
    const icon = menuMeta.Icon ? (
      <menuMeta.Icon className="me-2 text-2xl" />
    ) : null;
    if (m.type === 'link') {
      return {
        name: t(menuMeta.labelI18n),
        icon,
        url: m.href,
        disabled,
      };
    } else {
      return {
        name: t(menuMeta.labelI18n),
        icon,
        onClick: () => navigate({ ...location, pathname: m.path }),
        selected: location.pathname.startsWith(m.path),
        disabled,
      };
    }
  });
  const parentMenuItemOfMatchedChild = useParentMenuItemOfMatchedChild();

  return (
    (expand || parentMenuItemOfMatchedChild === undefined) && (
      <PortalMenu menuItemList={menuItems} open={expand} onClick={onClick} />
    )
  );
}
