import { Icon as MuiIcon } from '@mui/material';
import classNames from 'classnames';
import { SVGProps } from 'react';

export type PropsVsIcon = {
  className?: string;
  style?: React.CSSProperties;
  onClick?: (e: React.MouseEvent<SVGSVGElement>) => void;
};

type VsIconOptions = {
  fill?: boolean;
  stroke?: boolean;
};

export type VsIcon = React.FC<PropsVsIcon>;

export type IconComponent = typeof MuiIcon | VsIcon;

export const basicClassNames = 'MuiSvgIcon-root w-font h-font';

export function genVsIconBaseProps(
  { className, style, onClick }: PropsVsIcon,
  { fill = true, stroke = false }: VsIconOptions = {}
): SVGProps<SVGSVGElement> {
  return {
    className: classNames(
      basicClassNames,
      {
        'fill-current': fill,
        'stroke-current': stroke,
      },
      className
    ),
    style: style,
    focusable: false,
    'aria-hidden': true,
    onClick,
  };
}
