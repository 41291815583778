import { PortalBackButton } from '@vs/ui/portal/back-btn';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';

import { useParentMenuItemOfMatchedChild } from './hooks';

export function SpaNavigateBackBtnByPathsConfig() {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const parentMenuItemOfMatchedChild = useParentMenuItemOfMatchedChild();

  const [matchedChildView, matchedChildViewOfChildView] = useMemo(() => {
    let matchedChildViewOfChildView;
    return [
      parentMenuItemOfMatchedChild?.childViews.filter(cv => {
        let matched = false;

        if (cv.childViews && cv.childViews.length > 0) {
          if (
            cv.childViews.some(ccv => {
              const matched = matchPath(
                `${parentMenuItemOfMatchedChild.path}${cv.path}/${ccv.path}`,
                location.pathname
              );
              if (matched) {
                matchedChildViewOfChildView = ccv;
              }
              return matched;
            })
          ) {
            matched = true;
          }
        }
        if (
          matchPath(
            `${parentMenuItemOfMatchedChild.path}${cv.path}`,
            location.pathname
          )
        ) {
          matched = true;
        }

        return matched;
      }),
      matchedChildViewOfChildView,
    ];
  }, [location.pathname, parentMenuItemOfMatchedChild]);
  const matchedChildViewi18nKey = matchedChildView?.[0]?.backBtni18n;
  const buttonLabelI18nKey =
    (matchedChildViewi18nKey
      ? `shared:${matchedChildView?.[0]?.backBtni18n}`
      : '') ||
    parentMenuItemOfMatchedChild?.menuMeta.labelI18n ||
    '';

  return (
    parentMenuItemOfMatchedChild !== undefined && (
      <PortalBackButton
        onClick={() => {
          if (matchedChildViewOfChildView) {
            navigate(-1);
          } else {
            navigate({
              ...location,
              pathname: `${parentMenuItemOfMatchedChild.path}${matchedChildViewOfChildView ? matchedChildView?.[0].path : ''}`,
            });
          }
        }}
      >
        {t(buttonLabelI18nKey)}
      </PortalBackButton>
    )
  );
}
