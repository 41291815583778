import { type DeployEnv } from '@vs/utils/envConfig';

export const deployEnv = import.meta.env.VITE_DEPLOY_ENV as DeployEnv;

export const isLocalDevMode =
  import.meta.hot !== undefined && import.meta.env.MODE === 'development';

export const basePath = '/account';

export const localesFolderPath = `${basePath}/locales`;
