import { useContext } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { ChildViews, RouteConfig, context } from '../spa-path-config-provider';

type PathsWithMenuAndChildView = Omit<RouteConfig, 'childViews' | 'menuMeta'> &
  Required<Pick<RouteConfig, 'childViews' | 'menuMeta'>>;
/**
 * If current path is child view and it's parent has menu meta,
 * return parent config.
 *
 * @export
 * @returns {PathsWithMenuAndChildView | undefined}
 */
export function useParentMenuItemOfMatchedChild():
  | PathsWithMenuAndChildView
  | undefined {
  const location = useLocation();
  const pathsConfig = useContext(context);

  const isMatchedChildView = (
    parentPath: string,
    childView: ChildViews
  ): boolean => {
    if (childView.childViews && childView.childViews.length > 0) {
      const hasMatchedNestedChild = childView.childViews.some(nestedChildView =>
        matchPath(
          `${parentPath}${childView.path}/${nestedChildView.path}`,
          location.pathname
        )
      );
      if (hasMatchedNestedChild) {
        return true;
      }
    }
    return (
      matchPath(`${parentPath}${childView.path}`, location.pathname) !== null
    );
  };

  const findMatchingParent = (
    pathConfig: PathsWithMenuAndChildView[]
  ): PathsWithMenuAndChildView | undefined => {
    return pathConfig.find(config =>
      config.childViews?.some(childView =>
        isMatchedChildView(config.path, childView)
      )
    );
  };

  const filteredPathsConfig = pathsConfig.filter(
    p =>
      p.type === 'path' &&
      p.childViews !== undefined &&
      p.menuMeta !== undefined
  ) as PathsWithMenuAndChildView[];

  return findMatchingParent(filteredPathsConfig);
}
