import { VsIcon, genVsIconBaseProps } from '../shared';

export const TeamOneIcon: VsIcon = props => {
  return (
    <svg {...genVsIconBaseProps(props)} viewBox="0 0 38 38">
      <g clip-path="url(#clip0_5996_619)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.5641 2.25814C15.4223 -0.194356 20.8991 -0.334981 25.6766 1.48189C26.3723 1.74627 26.7191 2.53189 26.4491 3.22564C25.6898 5.17377 24.2854 6.88752 22.3335 8.01439C21.7935 8.32752 21.2348 8.57877 20.6666 8.77564C18.6923 6.13377 15.0023 5.32377 12.0773 7.01127C9.15414 8.69877 8.01039 12.3006 9.31164 15.3306C8.85789 15.7244 8.35914 16.0825 7.81914 16.3938C5.86539 17.5225 3.68102 17.8806 1.61289 17.5638C0.877893 17.4513 0.371643 16.7575 0.489768 16.0225C1.30352 10.9769 4.16664 6.30439 8.71914 3.32314C9.01539 3.13002 9.31727 2.94252 9.62664 2.76439C9.93602 2.58627 10.2491 2.41752 10.5641 2.25814ZM20.3385 12.73C20.3385 15.469 18.1181 17.6894 15.3791 17.6894C12.6402 17.6894 10.4198 15.469 10.4198 12.73C10.4198 9.99103 12.6402 7.77064 15.3791 7.77064C18.1181 7.77064 20.3385 9.99103 20.3385 12.73Z"
          fill="#3C5AAA"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.3986 20.3125C18.1376 20.3226 20.3498 22.5511 20.3398 25.2901C20.3298 28.029 18.1013 30.2413 15.3623 30.2312C12.6233 30.2212 10.4111 27.9927 10.4211 25.2537C10.4312 22.5147 12.6597 20.3025 15.3986 20.3125ZM20.6686 29.2268C20.6687 29.2267 20.6688 29.2265 20.6689 29.2263H20.6671C20.6676 29.2265 20.6681 29.2267 20.6686 29.2268ZM18.6927 30.9869C19.4613 30.5427 20.1324 29.9466 20.6686 29.2268C21.2362 29.4218 21.7925 29.6747 22.3321 29.9857C24.2839 31.1126 25.6883 32.8263 26.4477 34.7744C26.7177 35.4682 26.3708 36.2538 25.6752 36.5182C20.8977 38.3351 15.4208 38.1944 10.5627 35.7419C10.2477 35.5826 9.93456 35.4138 9.62519 35.2357C9.31581 35.0576 9.01394 34.8701 8.71769 34.6769C4.16519 31.6957 1.30394 27.0232 0.488313 21.9776C0.368313 21.2426 0.874563 20.5488 1.61144 20.4363C3.67956 20.1213 5.86581 20.4794 7.81769 21.6063C8.35769 21.9176 8.85644 22.2757 9.31019 22.6694C8.72519 24.0269 8.61269 25.5307 9.00081 26.9801C9.45831 28.6826 10.5496 30.1076 12.0777 30.9888C13.6058 31.8719 15.3852 32.1044 17.0896 31.6488C17.6577 31.4969 18.1958 31.2738 18.6927 30.9869Z"
          fill="#3AC9CC"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.7181 20.0652C37.4143 25.499 34.7968 30.3121 30.8349 33.5408C30.2556 34.0115 29.4024 33.9196 28.9374 33.3383C27.6287 31.7052 26.8468 29.6333 26.8468 27.3796C26.8468 26.7552 26.9068 26.1458 27.0212 25.5552C30.2949 25.1671 32.8431 22.3771 32.8431 19.0002C32.8431 15.6233 30.2949 12.8333 27.0212 12.4452C26.9068 11.8546 26.8468 11.2452 26.8468 10.6208C26.8468 8.36709 27.6287 6.29522 28.9374 4.66209C29.4043 4.08084 30.2574 3.98897 30.8349 4.45959C34.7968 7.68834 37.4143 12.5033 37.7181 17.9352C37.7368 18.2877 37.7481 18.644 37.7481 19.0002C37.7481 19.3565 37.7387 19.7127 37.7181 20.0652ZM31.2024 19.0002C31.2024 21.7392 28.9821 23.9596 26.2431 23.9596C23.5041 23.9596 21.2837 21.7392 21.2837 19.0002C21.2837 16.2612 23.5041 14.0408 26.2431 14.0408C28.9821 14.0408 31.2024 16.2612 31.2024 19.0002Z"
          fill="#DB0025"
        />
      </g>
      <defs>
        <clipPath id="clip0_5996_619">
          <rect
            width="37.5"
            height="37.5"
            fill="white"
            transform="translate(0.25 0.25)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
