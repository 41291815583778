import {
  ChildViews,
  FlattenRouteConfig,
  PathConfigs,
  RouteConfig,
} from './spa-path-config-provider';

function flattenViews(
  views: RouteConfig[] | ChildViews[],
  level = 1
): FlattenRouteConfig[] {
  let flatViews: FlattenRouteConfig[] = [];
  level = level + 1;
  views.forEach(view => {
    const { childViews, ...rest } = view;
    flatViews.push(rest);
    if (childViews && childViews.length > 0) {
      const flattenedChildViews = flattenViews(childViews, level).map(child => {
        return {
          ...child,
          path:
            level > 2 ? `${view.path}/${child.path}` : view.path + child.path,
        };
      });
      flatViews = flatViews.concat(flattenedChildViews);
    }
  });

  return flatViews;
}

export function flattenAppPathsAndView(pathsConfig: PathConfigs) {
  return flattenViews(
    pathsConfig.filter(p => p.type === 'path') as RouteConfig[]
  );
}

// please flatten the object above with nested childViews
function normalizePath(path: string) {
  return path.startsWith('/') ? path : `/${path}`;
}

export function generatePathsConfig(pathsConfig: PathConfigs): PathConfigs {
  return pathsConfig.map(p => {
    if (p.type === 'link') {
      return p;
    } else {
      const childViews = p.childViews?.map(cv => ({
        ...cv,
        path: normalizePath(cv.path),
      }));
      return { ...p, path: normalizePath(p.path), childViews };
    }
  });
}
