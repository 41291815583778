import { useAutoLogoutWhenNoAccount } from '@vs/hooks/hook-auto-logout-if-no-account';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { usePathMeta } from '@vs/shared-context/spa-path-config-provider';
import { useHandleI18nSwitch } from '@vs/utils/i18n';

type Returns = {
  /**
   * indicate wrapped common hooks are initialing
   */
  isInitializing: boolean;
};

/**
 * custom hooks wrapper of all common hooks that are required when app initializing
 *
 * @export
 * @returns {Returns}
 */
export function useVsPortalCommonInitialize(): Returns {
  const pathMeta = usePathMeta();
  const { isInitializing: isInitializingI18n } = useHandleI18nSwitch({
    skipAccountLang: pathMeta.isPublicPath,
  });
  const { isInitializing } = useAutoLogoutWhenNoAccount({
    skipAccountCheck: pathMeta.isPublicPath,
  });

  return { isInitializing: isInitializingI18n || isInitializing };
}
