import { useAccountInfo } from '@vs/hooks/hook-account-info';
import { useSignOut } from '@vs/oidc-client';
import { useEffect } from 'react';

type Options = {
  /**
   * skip this hooks function
   * (for example: entering public path therefore no required to check account status)
   */
  skipAccountCheck: boolean;
};
type Returns = {
  /**
   * indicate api data is fetching
   */
  isInitializing: boolean;
};

const defaultOptions = { skipAccountCheck: false };

/**
 * will log user out when app initializing if can't get user account data
 * (for example: user delete his/her account but app is still in log in session)
 *
 * @export
 * @param {Options} [options=defaultOptions]
 * @returns {Returns}
 */
export function useAutoLogoutWhenNoAccount(
  options: Options = defaultOptions
): Returns {
  const { skipAccountCheck = false } = options;
  const signout = useSignOut();
  const {
    data: account,
    isLoading,
    isUninitialized,
  } = useAccountInfo({ skip: skipAccountCheck });
  const shouldLogout =
    !skipAccountCheck && !isUninitialized && !isLoading && !account;

  useEffect(() => {
    if (shouldLogout) {
      signout();
    }
  }, [shouldLogout, signout]);

  return {
    isInitializing:
      !skipAccountCheck && (isUninitialized || isLoading || !account),
  };
}

export default useAutoLogoutWhenNoAccount;
