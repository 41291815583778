import { vsAccountApiBase as api } from './vsAccountApiBase';

const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    accountApiControllerFind: build.query<
      AccountApiControllerFindApiResponse,
      AccountApiControllerFindApiArg
    >({
      query: queryArg => ({
        url: `/account/api/v1/account/${queryArg.accountId}/profile`,
      }),
    }),
    accountApiControllerUpdate: build.mutation<
      AccountApiControllerUpdateApiResponse,
      AccountApiControllerUpdateApiArg
    >({
      query: queryArg => ({
        url: `/account/api/v1/account/${queryArg.accountId}/profile`,
        method: 'PATCH',
        body: queryArg.updateAccountDto,
      }),
    }),
    accountApiControllerSendVerifyEmail: build.query<
      AccountApiControllerSendVerifyEmailApiResponse,
      AccountApiControllerSendVerifyEmailApiArg
    >({
      query: queryArg => ({
        url: `/account/api/v1/account/${queryArg.accountId}/send/verify-email`,
      }),
    }),
    accountApiControllerVerifyAccountEmail: build.query<
      AccountApiControllerVerifyAccountEmailApiResponse,
      AccountApiControllerVerifyAccountEmailApiArg
    >({
      query: queryArg => ({
        url: `/account/api/v1/account/verify-email`,
        params: { hash_id: queryArg.hashId },
      }),
    }),
    accountApiControllerSendDelete: build.mutation<
      AccountApiControllerSendDeleteApiResponse,
      AccountApiControllerSendDeleteApiArg
    >({
      query: queryArg => ({
        url: `/account/api/v1/account/${queryArg.accountId}/send/delete`,
        method: 'POST',
      }),
    }),
    accountApiControllerDeleteAccountByHashId: build.mutation<
      AccountApiControllerDeleteAccountByHashIdApiResponse,
      AccountApiControllerDeleteAccountByHashIdApiArg
    >({
      query: queryArg => ({
        url: `/account/api/v1/account/delete`,
        method: 'DELETE',
        params: { hash_id: queryArg.hashId },
      }),
    }),
    accountApiControllerGetEntityInfo: build.query<
      AccountApiControllerGetEntityInfoApiResponse,
      AccountApiControllerGetEntityInfoApiArg
    >({
      query: queryArg => ({
        url: `/account/api/v1/account/${queryArg.accountId}/entity-info`,
      }),
    }),
    managementAccountRoleApiControllerUpdate: build.mutation<
      ManagementAccountRoleApiControllerUpdateApiResponse,
      ManagementAccountRoleApiControllerUpdateApiArg
    >({
      query: queryArg => ({
        url: `/account/api/v1/management/account/${queryArg.accountId}/role/${queryArg.roleId}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
    }),
    managementAccountApiControllerFindDetail: build.query<
      ManagementAccountApiControllerFindDetailApiResponse,
      ManagementAccountApiControllerFindDetailApiArg
    >({
      query: queryArg => ({
        url: `/account/api/v1/management/account/detail/${queryArg.shardRegion}/${queryArg.userId}`,
      }),
    }),
    managementAccountApiControllerFindList: build.query<
      ManagementAccountApiControllerFindListApiResponse,
      ManagementAccountApiControllerFindListApiArg
    >({
      query: queryArg => ({
        url: `/account/api/v1/management/account/list`,
        params: {
          cur_page: queryArg.curPage,
          page_size: queryArg.pageSize,
          account: queryArg.account,
        },
      }),
    }),
    managementAccountApiControllerFindAll: build.mutation<
      ManagementAccountApiControllerFindAllApiResponse,
      ManagementAccountApiControllerFindAllApiArg
    >({
      query: queryArg => ({
        url: `/account/api/v1/management/account/list`,
        method: 'POST',
        body: queryArg.getUserListProfileByIdDto,
      }),
    }),
    managementConsentApiControllerGet: build.query<
      ManagementConsentApiControllerGetApiResponse,
      ManagementConsentApiControllerGetApiArg
    >({
      query: () => ({ url: `/account/api/v1/management/consent` }),
    }),
    managementConsentApiControllerUpsert: build.mutation<
      ManagementConsentApiControllerUpsertApiResponse,
      ManagementConsentApiControllerUpsertApiArg
    >({
      query: queryArg => ({
        url: `/account/api/v1/management/consent`,
        method: 'POST',
        body: queryArg.upsertConsentDto,
      }),
    }),
    avatarApiControllerGet: build.query<
      AvatarApiControllerGetApiResponse,
      AvatarApiControllerGetApiArg
    >({
      query: queryArg => ({
        url: `/account/api/v1/account/${queryArg.accountId}/avatar`,
      }),
    }),
    avatarApiControllerUpdate: build.mutation<
      AvatarApiControllerUpdateApiResponse,
      AvatarApiControllerUpdateApiArg
    >({
      query: queryArg => ({
        url: `/account/api/v1/account/${queryArg.accountId}/avatar`,
        method: 'PATCH',
        body: queryArg.body,
      }),
    }),
    avatarApiControllerDelete: build.mutation<
      AvatarApiControllerDeleteApiResponse,
      AvatarApiControllerDeleteApiArg
    >({
      query: queryArg => ({
        url: `/account/api/v1/account/${queryArg.accountId}/avatar`,
        method: 'DELETE',
      }),
    }),
    credentialApiControllerUpdate: build.mutation<
      CredentialApiControllerUpdateApiResponse,
      CredentialApiControllerUpdateApiArg
    >({
      query: queryArg => ({
        url: `/account/api/v1/account/${queryArg.accountId}/password`,
        method: 'PATCH',
        body: queryArg.updateAccountCredentialDto,
      }),
    }),
    marketingApiControllerFind: build.query<
      MarketingApiControllerFindApiResponse,
      MarketingApiControllerFindApiArg
    >({
      query: queryArg => ({
        url: `/account/api/v1/account/${queryArg.accountId}/marketing`,
      }),
    }),
    marketingApiControllerUpdate: build.mutation<
      MarketingApiControllerUpdateApiResponse,
      MarketingApiControllerUpdateApiArg
    >({
      query: queryArg => ({
        url: `/account/api/v1/account/${queryArg.accountId}/marketing`,
        method: 'PATCH',
        body: queryArg.updateAccountMarketingDto,
      }),
    }),
    managementStatusControllerUpdate: build.mutation<
      ManagementStatusControllerUpdateApiResponse,
      ManagementStatusControllerUpdateApiArg
    >({
      query: queryArg => ({
        url: `/account/api/v1/management/account/status`,
        method: 'PUT',
        body: queryArg.bulkUpdateAccountStatusDto,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as vsAccountApiInjected };
export type AccountApiControllerFindApiResponse =
  /** status 200  */ GetAccountProfileVo;
export type AccountApiControllerFindApiArg = {
  accountId: string;
};
export type AccountApiControllerUpdateApiResponse = unknown;
export type AccountApiControllerUpdateApiArg = {
  accountId: string;
  updateAccountDto: UpdateAccountDto;
};
export type AccountApiControllerSendVerifyEmailApiResponse = unknown;
export type AccountApiControllerSendVerifyEmailApiArg = {
  accountId: string;
};
export type AccountApiControllerVerifyAccountEmailApiResponse = unknown;
export type AccountApiControllerVerifyAccountEmailApiArg = {
  hashId: string;
};
export type AccountApiControllerSendDeleteApiResponse = unknown;
export type AccountApiControllerSendDeleteApiArg = {
  accountId: string;
};
export type AccountApiControllerDeleteAccountByHashIdApiResponse = unknown;
export type AccountApiControllerDeleteAccountByHashIdApiArg = {
  hashId: string;
};
export type AccountApiControllerGetEntityInfoApiResponse =
  /** status 200  */ GetVsEntityInfoVo;
export type AccountApiControllerGetEntityInfoApiArg = {
  accountId: string;
};
export type ManagementAccountRoleApiControllerUpdateApiResponse = unknown;
export type ManagementAccountRoleApiControllerUpdateApiArg = {
  accountId: string;
  roleId: string;
  body: string[];
};
export type ManagementAccountApiControllerFindDetailApiResponse =
  /** status 200  */ AccountPiiDetailVo;
export type ManagementAccountApiControllerFindDetailApiArg = {
  userId: string;
  /** shard region */
  shardRegion: any;
};
export type ManagementAccountApiControllerFindListApiResponse =
  /** status 200  */ GetMgmtAccountListVo;
export type ManagementAccountApiControllerFindListApiArg = {
  /** current page */
  curPage?: number;
  /** page size */
  pageSize?: number;
  /** account email for searching */
  account?: string;
};
export type ManagementAccountApiControllerFindAllApiResponse =
  /** status 200  */ GetAccountFullInfoVo;
export type ManagementAccountApiControllerFindAllApiArg = {
  getUserListProfileByIdDto: GetUserListProfileByIdDto;
};
export type ManagementConsentApiControllerGetApiResponse = unknown;
export type ManagementConsentApiControllerGetApiArg = void;
export type ManagementConsentApiControllerUpsertApiResponse = unknown;
export type ManagementConsentApiControllerUpsertApiArg = {
  upsertConsentDto: UpsertConsentDto;
};
export type AvatarApiControllerGetApiResponse =
  /** status 200  */ GetAccountAvatarVo;
export type AvatarApiControllerGetApiArg = {
  accountId: string;
};
export type AvatarApiControllerUpdateApiResponse = unknown;
export type AvatarApiControllerUpdateApiArg = {
  accountId: string;
  /** Avatar for 48x48 px and 150x150 px. */
  body: {
    images?: Blob[];
  };
};
export type AvatarApiControllerDeleteApiResponse = unknown;
export type AvatarApiControllerDeleteApiArg = {
  accountId: string;
};
export type CredentialApiControllerUpdateApiResponse = unknown;
export type CredentialApiControllerUpdateApiArg = {
  accountId: string;
  updateAccountCredentialDto: UpdateAccountCredentialDto;
};
export type MarketingApiControllerFindApiResponse =
  /** status 200  */ GetAccountMarketingVo;
export type MarketingApiControllerFindApiArg = {
  accountId: string;
};
export type MarketingApiControllerUpdateApiResponse = unknown;
export type MarketingApiControllerUpdateApiArg = {
  accountId: string;
  updateAccountMarketingDto: UpdateAccountMarketingDto;
};
export type ManagementStatusControllerUpdateApiResponse = unknown;
export type ManagementStatusControllerUpdateApiArg = {
  bulkUpdateAccountStatusDto: BulkUpdateAccountStatusDto;
};
export type GetAccountProfileVo = {
  id: string;
  account: string;
  email: string | null;
  email_is_verified: boolean;
  has_password: boolean;
  display_name: string;
  given_name: string;
  family_name: string;
  phone_number: string;
  occupation_code: string;
  country_code: string;
  region: string;
  city: string;
  avatar_url: string;
  lang_code: string;
  /** list of user role */
  role_code: string[];
  status: 'active' | 'inactive' | 'locked' | 'suspended' | 'deleted';
  marketing: boolean;
};
export type UpdateAccountDto = {
  email?: string;
  email_is_verified?: boolean;
  shard_region: 'US' | 'DE' | 'CA' | 'SG';
  display_name?: string;
  given_name?: string;
  family_name?: string;
  phone?: string;
  occupation_code?: string;
  country_code: string;
  /** country */
  region?: string;
  city?: string;
  avatar_url?: string;
  lang_code?: string;
};
export type VsEntityInfoProfileDetail = {
  contact_email: string;
  contact_phone: string;
  zipcode: string;
  country_code: string;
  country: string;
  region: string;
  city: string;
  address: string;
};
export type VsEntityInfoDomainDetail = {
  is_verified: boolean;
  verify_type: string;
  domain_name: string;
  is_main: boolean;
};
export type VsEntityInfoEntitiesDetail = {
  id: string;
  name: string;
  is_current: boolean;
  is_owner: boolean;
  is_su: boolean;
  role: string[];
  policy_codes: any[];
};
export type GetVsEntityInfoDetail = {
  id: string;
  name: string;
  generics_code: string;
  domain_type_code: string;
  area: string;
  create_time: string;
  modify_time: string;
  profile: VsEntityInfoProfileDetail;
  domains: VsEntityInfoDomainDetail[];
  entities: VsEntityInfoEntitiesDetail[];
};
export type GetVsEntityInfoVo = {
  data: GetVsEntityInfoDetail;
};
export type AccountPiiDetailVo = {
  id: string;
  account: string;
  given_name: string;
  family_name: string;
  shard_region: object;
  create_time: string;
};
export type PaginationMetaVo = {
  current_page: number;
  per_page: number;
  total_items: number;
  total_pages: number;
};
export type GetMgmtAccountDataListVo = {
  id: string;
  account: string;
  shard_region: object;
  create_time: string;
};
export type GetMgmtAccountListVo = {
  meta: PaginationMetaVo;
  data: GetMgmtAccountDataListVo[];
};
export type ConsentDetailVo = {
  code: string;
  agreement: boolean;
};
export type GetAccountFullInfoVo = {
  id: string;
  account: string;
  email: string;
  email_is_verified: boolean;
  has_password: boolean;
  display_name: string;
  given_name: string;
  family_name: string;
  phone_number: string;
  occupation_code: string;
  country_code: string;
  region: string;
  city: string;
  avatar_url: string;
  lang_code: string;
  /** list of user role */
  role_code: string[];
  status: 'active' | 'inactive' | 'locked' | 'suspended' | 'deleted';
  marketing: boolean;
  consent?: ConsentDetailVo[];
};
export type GetUserListProfileByIdDto = {
  /** id list of array. */
  ids: string[];
  /** account email list of array. */
  accounts: string[];
  /** email list of array. */
  details: boolean;
};
export type UpsertConsentDto = {
  data: string[];
};
export type GetAccountAvatarVo = {
  id: string;
  avatar_url: string;
};
export type UpdateAccountCredentialDto = {
  password: string;
  new_password: string;
};
export type GetAccountMarketingVo = {
  id: string;
  email_campaign: boolean;
};
export type UpdateAccountMarketingDto = {
  email_campaign: boolean;
};
export type BulkUpdateAccountStatusDetail = {
  /** Account id. */
  id: string;
  status: 'active' | 'inactive' | 'locked' | 'suspended' | 'deleted';
};
export type BulkUpdateAccountStatusDto = {
  data: BulkUpdateAccountStatusDetail[];
};
export const {
  useAccountApiControllerFindQuery,
  useAccountApiControllerUpdateMutation,
  useAccountApiControllerSendVerifyEmailQuery,
  useAccountApiControllerVerifyAccountEmailQuery,
  useAccountApiControllerSendDeleteMutation,
  useAccountApiControllerDeleteAccountByHashIdMutation,
  useAccountApiControllerGetEntityInfoQuery,
  useManagementAccountRoleApiControllerUpdateMutation,
  useManagementAccountApiControllerFindDetailQuery,
  useManagementAccountApiControllerFindListQuery,
  useManagementAccountApiControllerFindAllMutation,
  useManagementConsentApiControllerGetQuery,
  useManagementConsentApiControllerUpsertMutation,
  useAvatarApiControllerGetQuery,
  useAvatarApiControllerUpdateMutation,
  useAvatarApiControllerDeleteMutation,
  useCredentialApiControllerUpdateMutation,
  useMarketingApiControllerFindQuery,
  useMarketingApiControllerUpdateMutation,
  useManagementStatusControllerUpdateMutation,
} = injectedRtkApi;
