import { List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import classNames from 'classnames';
import { Fragment } from 'react';

export type MenuItemInterface<TExtended = unknown> = TExtended & {
  name: string;
  url?: string;
  icon?: React.ReactNode | undefined;
  selected?: boolean;
  selectedListButtonClassName?: string;
  underline?: boolean;
  onClick?: () => void;
  hide?: boolean;
  disabled?: boolean;
  childViews?: MenuItemInterface<TExtended>[];
  childViewListClassName?: string;
};

export interface UiPortalMenuProps {
  menuItemList: MenuItemInterface[];
  open: boolean;
  onClick?: (item: MenuItemInterface) => void;
  layer?: number;
  childViewListClassName?: string;
}

export function PortalMenu({
  menuItemList,
  open = false,
  onClick: generalOnClick = () => {},
  layer = 1,
  childViewListClassName = '',
}: UiPortalMenuProps) {
  return (
    <List className={classNames(layer === 2 && 'pl-4', childViewListClassName)}>
      {menuItemList
        ?.filter(e => !e.hide)
        .filter(e => e.name)
        .map(item => {
          const {
            name,
            icon,
            selected,
            selectedListButtonClassName,
            underline,
            url,
            onClick,
            disabled,
            childViews,
          } = item;
          return (
            <Fragment key={name}>
              <ListItem
                className={`mb-2 ${
                  underline ? 'border-vs-neut-300 border-b pb-2' : ''
                }`}
                disablePadding
              >
                <ListItemButton
                  className={classNames(
                    `rounded-r-3xl`,
                    selected && selectedListButtonClassName,
                    {
                      selected,
                    }
                  )}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    columnGap: 1,
                    px: 2.5,
                    paddingLeft: '24px',
                    '&:hover': {
                      background: '#e6eef8',
                    },
                    '&.selected': {
                      background: '#e6eef8',
                    },
                  }}
                  onClick={() => {
                    if (typeof onClick === 'function') {
                      onClick();
                    } else if (url) {
                      window.open(url, '_blank');
                    }
                    generalOnClick(item);
                  }}
                  disabled={disabled}
                >
                  {icon}
                  <ListItemText
                    className={classNames(
                      'lg:block',
                      open ? 'block' : 'hidden'
                    )}
                    primary={name}
                    primaryTypographyProps={{
                      className: 'vs-label-md-medium',
                    }}
                  />
                </ListItemButton>
              </ListItem>
              {childViews && (
                <PortalMenu
                  menuItemList={childViews}
                  open={true}
                  layer={2}
                  childViewListClassName={item.childViewListClassName}
                />
              )}
            </Fragment>
          );
        })}
    </List>
  );
}

export default PortalMenu;
