import { SigninRedirectArgs, State } from 'oidc-client-ts';
import { ReactNode, useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import {
  Location,
  Route,
  Routes,
  useHref,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import {
  ArgsUseAutoSilentRefreshToken,
  OidcRedirectState,
  useAutoLogin,
  useAutoSilentRefreshToken,
  useIsAuthOmitPath,
  useOidcConfig,
} from './hooks';
import { useHandleSameClientLogout } from './hooks/useHandleSameClientLogout';

export type PropsAuthGuard = {
  renderLoadingUI?: () => ReactNode;
  renderComponent?: () => ReactNode;
  extraQueryParams?: SigninRedirectArgs['extraQueryParams'];
  generateSignInState?: (args: { location: Location }) => OidcRedirectState;
  authOmitPaths?: string[];
} & Pick<
  ArgsUseAutoSilentRefreshToken,
  'onTokenRefreshStart' | 'onTokenRefreshEnd' | 'onTokenExpired'
>;
export function AuthGuard({
  renderLoadingUI = () => null,
  renderComponent = () => null,
  generateSignInState = () => ({}),
  extraQueryParams,
  authOmitPaths = [],
  onTokenExpired,
  onTokenRefreshEnd,
  onTokenRefreshStart,
}: PropsAuthGuard) {
  const location = useLocation();
  const isOmitAuthPath = useIsAuthOmitPath(authOmitPaths);
  const signInState: OidcRedirectState = {
    refererPath: `${location.pathname}${location.search}${location.hash}`,
    ...generateSignInState({ location }),
  };
  const { done } = useAutoLogin({
    skip: isOmitAuthPath,
    signinRedirectArgs: {
      state: signInState,
      extraQueryParams,
    },
  });

  useHandleSameClientLogout();
  useAutoSilentRefreshToken({
    skip: isOmitAuthPath,
    onTokenExpired,
    onTokenRefreshEnd,
    onTokenRefreshStart,
  });

  if (!done) {
    return renderLoadingUI();
  }
  return renderComponent();
}

export type PropsAuthedRedirect = {
  renderLoadingUI?: () => ReactNode;
  onReceiveSigninState?: (signInState: OidcRedirectState | undefined) => void;
};
export function AuthedRedirect({
  onReceiveSigninState,
  renderLoadingUI = () => null,
}: PropsAuthedRedirect) {
  const navigate = useNavigate();
  const { isLoading, isAuthenticated, error, user, signoutRedirect } =
    useAuth();

  useEffect(() => {
    if (!isLoading) {
      if (error || !isAuthenticated) {
        if (error) {
          console.error('Auth error:', error);
          signoutRedirect({ state: { refererPath: '/' } as OidcRedirectState });
        }
      } else {
        const state = user?.state as OidcRedirectState;
        if (typeof onReceiveSigninState === 'function') {
          onReceiveSigninState(state);
        }
        navigate(state?.refererPath ?? '/');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return renderLoadingUI();
}

export async function getLogoutRedirectState() {
  const stateQuery = new URLSearchParams(window.location.search).get('state');
  if (stateQuery) {
    const storageKey = `oidc.${stateQuery}`;
    const storageStr = localStorage.getItem(storageKey);
    if (storageStr) {
      localStorage.removeItem(storageKey);
      try {
        const data = await State.fromStorageString(storageStr);
        return data;
      } catch {
        return null;
      }
    }
  }
  return null;
}

export function LogoutRedirect() {
  const defaultPath = useHref('/');

  useEffect(() => {
    getLogoutRedirectState().then(state => {
      const path =
        (state !== null && (state.data as OidcRedirectState)?.refererPath) ||
        defaultPath;
      window.location.href = `${window.location.origin}${path}`;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}

export type PropsAuthRoutes = {
  authRedirectPath?: string;
  postLogoutRedirectPath?: string;
  propsAuthGuard?: PropsAuthGuard;
  propsAuthedRedirect?: PropsAuthedRedirect;
} & Pick<PropsAuthGuard, 'renderComponent' | 'renderLoadingUI'>;

export function AuthRoutes({
  renderComponent,
  renderLoadingUI,
  propsAuthGuard,
  propsAuthedRedirect,
}: PropsAuthRoutes) {
  const originWithBase = `${window.location.origin}${useHref('')}`;
  const { redirect_uri, post_logout_redirect_uri } = useOidcConfig();

  function retrievePath(url: string) {
    return url.replace(originWithBase, '');
  }

  return (
    <Routes>
      <Route
        path={retrievePath(redirect_uri)}
        element={
          <AuthedRedirect
            renderLoadingUI={renderLoadingUI}
            {...propsAuthedRedirect}
          />
        }
      />
      {post_logout_redirect_uri !== undefined && (
        <Route
          path={retrievePath(post_logout_redirect_uri)}
          element={<LogoutRedirect />}
        />
      )}
      <Route
        path="*"
        element={
          <AuthGuard
            renderComponent={renderComponent}
            renderLoadingUI={renderLoadingUI}
            {...propsAuthGuard}
          />
        }
      />
    </Routes>
  );
}
