import { SigninRedirectArgs } from 'oidc-client-ts';
import { useEffect, useState } from 'react';
import { hasAuthParams, useAuth } from 'react-oidc-context';

export type ArgsUseAutoLogin = {
  skip?: boolean;
  signinRedirectArgs?: SigninRedirectArgs;
};

export function useAutoLogin(args?: ArgsUseAutoLogin) {
  const { skip = false, signinRedirectArgs } = args ?? {};
  const { isLoading, isAuthenticated, activeNavigator, signinRedirect } =
    useAuth();
  const [hasTriedSignin, setHasTriedSignin] = useState(false);

  useEffect(() => {
    if (
      !skip &&
      !hasAuthParams() &&
      !isAuthenticated &&
      !activeNavigator &&
      !isLoading &&
      !hasTriedSignin
    ) {
      signinRedirect(signinRedirectArgs);
      setHasTriedSignin(true);
    }
  }, [
    skip,
    isLoading,
    isAuthenticated,
    activeNavigator,
    signinRedirect,
    hasTriedSignin,
    signinRedirectArgs,
  ]);

  return { done: skip || isAuthenticated };
}
