import { setOpenDrawer } from '@vs/rtk/slices/genericGlobal';
import { SpaMenuByPathsConfig } from '@vs/shared-context/spa-path-config-provider';
import { memo } from 'react';

import { useAppDispatch, useAppSelector } from '../../redux/hooks';

function Menu() {
  const dispatch = useAppDispatch();
  const openDrawer = useAppSelector(s => s.general.openDrawer);

  return (
    <SpaMenuByPathsConfig
      expand={openDrawer}
      onClick={() => {
        dispatch(setOpenDrawer(false));
      }}
    />
  );
}

export default memo(Menu);
