import { DeployEnv, getEnvConfig } from '@vs/utils/envConfig';
import { memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import {
  PortalFooterContainer,
  PropsPortalFooterContainer,
} from './portal-footer-container';

export type PortalFooterProps = {
  deployEnv?: DeployEnv;
  mode?: PropsPortalFooterContainer['mode'];
};

export function PortalFooter({ deployEnv = 'dev', mode }: PortalFooterProps) {
  const { t } = useTranslation();
  const {
    appUrl: { legalDoc },
  } = getEnvConfig({ deployEnv });

  return (
    <PortalFooterContainer mode={mode}>
      <Trans
        i18nKey="shared:footer.privacy_policy"
        t={t}
        components={{
          a: (
            <a
              href={`${legalDoc}/privacy-policy`}
              target="_blank"
              rel="noreferrer"
              className="hover:underline"
            >
              0
            </a>
          ),
        }}
      />
      <Trans
        i18nKey="shared:footer.term_of_use"
        t={t}
        components={{
          a: (
            <a
              href={`${legalDoc}/terms-of-use`}
              target="_blank"
              rel="noreferrer"
              className="hover:underline"
            >
              0
            </a>
          ),
        }}
      />
    </PortalFooterContainer>
  );
}

export default memo(PortalFooter);
