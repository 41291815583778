import CreditScoreIcon from '@mui/icons-material/CreditScore';
import InfoIcon from '@mui/icons-material/Info';
import ManageAccountsIcon from '@mui/icons-material/ManageAccountsOutlined';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActiveOutlined';
import SecurityIcon from '@mui/icons-material/SecurityOutlined';
import {
  PathConfigs,
  generatePathsConfig,
} from '@vs/shared-context/spa-path-config-provider';
import { lazy } from 'react';

import { deployEnv } from '.';

const Account = lazy(
  () =>
    import('../views/account/account' /* webpackChunkName: "view_account" */)
);
const Notification = lazy(
  () =>
    import(
      '../views/notifications/notification' /* webpackChunkName: "view_notification" */
    )
);
const Security = lazy(
  () =>
    import('../views/security/security' /* webpackChunkName: "view_security" */)
);
const ChangePasswordPage = lazy(
  () =>
    import(
      '../views/security/change-password' /* webpackChunkName: "view_security_change_pw" */
    )
);
const TwoFactorAuthentication = lazy(
  () =>
    import(
      '../views/security/two-factor-authentication' /* webpackChunkName: "view_security_two_factor_authentication" */
    )
);
const SignInMethodsPage = lazy(
  () =>
    import(
      '../views/security/sign-in-methods' /* webpackChunkName: "view_sign_in_methods" */
    )
);
const LandingPage = lazy(
  () =>
    import('../views/landingPage' /* webpackChunkName: "view_landing_page" */)
);
const QrCodeLogin = lazy(
  () =>
    import(
      '../views/utils/QrCodeLogin' /* webpackChunkName: "view_util_qrcode_login" */
    )
);
const Subscription = lazy(
  () =>
    import('../views/subscription' /* webpackChunkName: "view_subscription" */)
);

const Product = lazy(
  () =>
    import(
      '../views/subscription/product/product' /* webpackChunkName: "view_product" */
    )
);

const Payment = lazy(
  () =>
    import(
      '../views/subscription/payment/payment' /* webpackChunkName: "view_payment" */
    )
);

const PaymentSetting = lazy(
  () =>
    import(
      '../views/subscription/payment-setting/payment-setting' /* webpackChunkName: "view_payment_setting" */
    )
);

const PaymentHistory = lazy(
  () =>
    import(
      '../views/subscription/payment-history/payment-history' /* webpackChunkName: "view_payment_history" */
    )
);
const Redirect = lazy(
  () => import('../views/redirect' /* webpackChunkName: "view_redirect" */)
);

export const pathsConfig: PathConfigs = generatePathsConfig([
  {
    type: 'path',
    path: 'settings',
    isFallbackPath: true,
    View: Account,
    menuMeta: {
      order: 1,
      Icon: ManageAccountsIcon,
      labelI18n: 'account.menu.account',
    },
  },
  {
    type: 'path',
    path: 'notification',
    View: Notification,
    menuMeta: {
      order: 2,
      Icon: NotificationsActiveIcon,
      labelI18n: 'account.menu.notification',
    },
  },
  {
    type: 'path',
    path: 'security',
    View: Security,
    menuMeta: {
      order: 3,
      Icon: SecurityIcon,
      labelI18n: 'account.menu.security',
    },
    childViews: [
      {
        path: 'change-password',
        View: ChangePasswordPage,
      },
      {
        path: 'sign-in-methods',
        View: SignInMethodsPage,
      },
      {
        path: 'two-factor-authentication',
        View: TwoFactorAuthentication,
      },
    ],
  },
  {
    type: 'path',
    path: 'landing/:landing_type',
    View: LandingPage,
    meta: {
      isPublicPath: true,
      show: { avatar: false, menu: false },
      backGroundMode: 'doc',
    },
  },
  {
    type: 'link',
    href: 'https://www.viewsonic.com/global/support',
    menuMeta: {
      order: 5,
      Icon: InfoIcon,
      labelI18n: 'account.menu.support',
    },
  },
  {
    type: 'path',
    path: 'utils/qr-code-login',
    View: QrCodeLogin,
    meta: {
      show: { menu: false },
      backGroundMode: 'doc',
    },
  },
  {
    type: 'path',
    path: 'redirect',
    View: Redirect,
    meta: {
      isPublicPath: true,
      show: { header: false, footer: false, menu: false },
    },
  },
  {
    type: 'path',
    path: 'subscription',
    View: Subscription,
    menuMeta: {
      order: 4,
      Icon: CreditScoreIcon,
      labelI18n: 'account.menu.subscription',
    },
    hide: deployEnv !== 'dev',
    childViews: [
      {
        path: ':productFamilyId',
        View: Product,
        backBtni18n: 'common.btn.go_back',
        childViews: [
          {
            path: 'payment',
            View: Payment,
            backBtni18n: 'common.btn.go_back',
          },
        ],
      },
      {
        path: 'payment-history',
        View: PaymentHistory,
        backBtni18n: 'common.btn.go_back',
      },
      {
        path: 'payment-setting',
        View: PaymentSetting,
        backBtni18n: 'common.btn.go_back',
      },
    ],
  },
]);
