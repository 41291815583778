export enum HistorySliceTags {
  USER_Log = 'USER_Log',
  WINDOWS_Log = 'WINDOWS_Log',
}
export interface GetAppLog {
  id: string;
  create_time: string;
  last_time: string;
  user_agent: string;
  property: {
    third_sso: string;
  };
  physical_ip: string;
  sid: {
    city: string;
    country: string;
    region: string;
  };
  location: string;
  login_count: number;
}

export interface GetCompanionAppLog {
  android: GetAppLog;
  ios: GetAppLog;
}

export enum LogType {
  Web = 'web',
  Windows = 'windows',
  Android = 'android',
  Companion = 'companion',
}

export const logTypeOrder = {
  [LogType.Web]: 1,
  [LogType.Windows]: 2,
  [LogType.Android]: 3,
  [LogType.Companion]: 4,
};
export interface ErrorMessageInterface {
  nativeCode: string;
  status: number;
  responseCode: number;
  messageCode: number;
  message: string;
}
