import { usePathMeta } from '@vs/shared-context/spa-path-config-provider';
import { PortalLayout, PortalLayoutProps } from '@vs/ui/portal/layout';

/**
 * Show / hide portal layout component according to path config's meta
 *
 * @export
 * @param {PortalLayoutProps} props
 * @returns {ReactElement}
 */
export function PortalLayoutWithPathMeta({
  navigateBtn,
  sidebar,
  ...restProps
}: PortalLayoutProps) {
  const pathMeta = usePathMeta();

  return (
    <PortalLayout
      navigateBtn={pathMeta.show.menu && navigateBtn}
      sidebar={pathMeta.show.menu && sidebar}
      enableHeaderSpace={pathMeta.show.header}
      mode={pathMeta.backGroundMode}
      {...restProps}
    />
  );
}

export default PortalLayoutWithPathMeta;
