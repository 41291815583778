import { PortalLayoutMode } from '@vs/types';
import classNames from 'classnames';
import { ReactNode } from 'react';

export type PropsPortalFooterContainer = {
  children?: ReactNode;
  mode?: PortalLayoutMode;
};

export function PortalFooterContainer({
  children,
  mode = 'app',
}: PropsPortalFooterContainer) {
  return (
    <div
      className={classNames(
        'vs-body-sm-regular flex h-full w-full items-center justify-center space-x-6 py-4',
        { 'bg-vs-base-200': mode === 'app', 'bg-vs-base-300': mode === 'doc' }
      )}
    >
      {children}
    </div>
  );
}
