import { VsIcon, genVsIconBaseProps } from '../shared';

export const MyViewboardIcon: VsIcon = props => {
  return (
    <svg {...genVsIconBaseProps(props)} viewBox="0 0 41 30">
      <path
        d="M12.366 16.2266C12.366 14.8369 12.3202 13.6516 12.2755 12.672H15.1858L15.3451 14.1753H15.4139C15.8917 13.469 16.869 12.4204 18.7791 12.4204C20.2113 12.4204 21.3479 13.1497 21.8257 14.312H21.8716C22.2806 13.7424 22.7814 13.2864 23.3038 12.9671C23.918 12.603 24.5997 12.4204 25.4178 12.4204C27.5547 12.4204 29.1691 13.9238 29.1691 17.251V23.8147H25.8039V17.7529C25.8039 16.1347 25.2814 15.201 24.1665 15.201C23.3714 15.201 22.8019 15.7476 22.575 16.4092C22.4845 16.6596 22.4387 17.0248 22.4387 17.2982V23.8159H19.0735V17.5715C19.0735 16.1589 18.5727 15.201 17.482 15.201C16.5951 15.201 16.0727 15.8843 15.8676 16.454C15.7541 16.7273 15.7312 17.0466 15.7312 17.3199V23.8147H12.366V16.2266Z"
        fill="#DB0025"
      />
      <path
        d="M32.9408 12.6719L34.5999 18.1411C34.7821 18.8014 35.0089 19.6226 35.1453 20.2153H35.214C35.3733 19.6226 35.5555 18.7797 35.7148 18.1411L37.0794 12.6719H40.7849L38.1931 20.0097C36.6016 24.4303 35.5338 26.2082 34.2825 27.3245C33.0772 28.3731 31.8042 28.7371 30.9403 28.8508L30.2127 25.9118C30.6446 25.8429 31.19 25.6385 31.7137 25.3192C32.2362 25.0459 32.8045 24.4992 33.1459 23.9295C33.2593 23.7699 33.3281 23.5873 33.3281 23.4276C33.3281 23.3139 33.3052 23.1313 33.1688 22.858L29.099 12.6719H32.9408Z"
        fill="#DB0025"
      />
      <path
        d="M8.54352 29.4374C4.37231 29.4374 0.978149 26.0352 0.978149 21.8541C0.978149 19.3408 2.22215 17.0198 4.23838 15.6193C4.05497 14.8839 3.96207 14.1292 3.96207 13.3612C3.96207 8.23432 8.12241 4.0641 13.2372 4.0641C13.9105 4.0641 14.5777 4.13667 15.2293 4.27939C17.0657 2.01407 19.8059 0.690918 22.7657 0.690918C28.1254 0.690918 32.4848 5.06191 32.4848 10.4331H29.3959C29.3959 6.76846 26.4217 3.78714 22.7657 3.78714C20.4768 3.78714 18.3785 4.94822 17.1538 6.89182L16.4926 7.94163L15.327 7.52195C14.6573 7.28127 13.9539 7.15911 13.2384 7.15911C9.82733 7.15911 7.05216 9.94087 7.05216 13.36C7.05216 14.1849 7.21143 14.9867 7.52635 15.7427L8.10552 17.1384L6.72759 17.7528C5.11195 18.4736 4.06824 20.0834 4.06824 21.8529C4.06824 24.3262 6.07602 26.3399 8.54472 26.3399C8.62315 26.3399 8.70037 26.3375 8.77759 26.3339L8.85964 26.3315H22.5473V29.4277H8.89825C8.78121 29.4337 8.66297 29.4362 8.54472 29.4362L8.54352 29.4374Z"
        fill="#DB0025"
      />
    </svg>
  );
};
