import merge from 'lodash/merge';
import { useContext, useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { RequiredDeep } from 'type-fest';

import { context } from '../spa-path-config-provider';
import { PathMeta } from '../type';
import { flattenAppPathsAndView } from '../utils';

const defaultPathMeta: PathMeta = {
  show: { avatar: true, menu: true, header: true, footer: true },
  backGroundMode: 'app',
  isPublicPath: false,
};
/**
 * Use the meta of current path
 *
 * @export
 * @returns {RequiredDeep<PathMeta>}
 */
export function usePathMeta(): RequiredDeep<PathMeta> {
  const location = useLocation();
  const pathsConfig = useContext(context);
  const pathsAndViews = useMemo(
    () => flattenAppPathsAndView(pathsConfig),
    [pathsConfig]
  );
  const paths = pathsAndViews.find(({ path }) =>
    matchPath(String(path), location.pathname)
  );
  const meta = merge(
    {},
    defaultPathMeta,
    paths?.meta
  ) as RequiredDeep<PathMeta>;

  return meta;
}
