import { PortalAppLoading } from '@vs/components/portal-app-loading';
import { PortalAppToast } from '@vs/components/portal-app-toast';
import { PortalFooterWithPathMeta } from '@vs/components/portal-footer-with-path-meta';
import { PortalLayoutWithPathMeta } from '@vs/components/portal-layout-with-path-meta';
import { setOpenDrawer } from '@vs/rtk/slices/genericGlobal';
import { SpaNavigateBackBtnByPathsConfig } from '@vs/shared-context/spa-path-config-provider';
import { memo } from 'react';

import Menu from '../../components/menu/menu';
import { deployEnv } from '../../constants';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import AppContentRoutes from './AppContentRoutes';
import AppHeader from './AppHeader';

function AppContent() {
  const dispatch = useAppDispatch();
  const openDrawer = useAppSelector(s => s.general.openDrawer);

  return (
    <>
      <AppHeader />
      <PortalLayoutWithPathMeta
        size="full"
        navigateBtn={<SpaNavigateBackBtnByPathsConfig />}
        sidebar={<Menu />}
        content={<AppContentRoutes />}
        openDrawer={openDrawer}
        onDrawerClickAway={() => dispatch(setOpenDrawer(false))}
        footer={<PortalFooterWithPathMeta deployEnv={deployEnv} />}
      />
      <PortalAppLoading />
      <PortalAppToast />
    </>
  );
}

export default memo(AppContent);
