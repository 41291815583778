import { createApi } from '@reduxjs/toolkit/query/react';
import {
  ArgsInitSliceMeta,
  ReferenceOfMeta,
  dynamicFetchBaseFactory,
} from '@vs/rtk/utils';
import { getEnvConfig } from '@vs/utils/envConfig';

const referenceOfMeta: ReferenceOfMeta = {
  _baseUrl: undefined,
  _getTokenFunc: undefined,
};

export function initSliceMeta({
  deployEnv,
  getTokenFunc,
  oidcConfig,
}: ArgsInitSliceMeta) {
  referenceOfMeta._baseUrl = getEnvConfig({ deployEnv }).apiEndpoint.vs;
  referenceOfMeta._getTokenFunc = getTokenFunc;
}

export const vsCommonApiBase = createApi({
  reducerPath: 'vsCommonGenApi',
  keepUnusedDataFor: 60,
  baseQuery: dynamicFetchBaseFactory(referenceOfMeta),
  endpoints: () => ({}),
});
