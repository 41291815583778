import { usePathMeta } from '@vs/shared-context/spa-path-config-provider';
import { PortalFooter, PortalFooterProps } from '@vs/ui/portal/footer';

/**
 * Show / hide portal footer according to path config's meta
 *
 * @export
 * @param {PortalFooterProps} props
 * @returns {ReactElement}
 */
export function PortalFooterWithPathMeta(props: PortalFooterProps) {
  const pathMeta = usePathMeta();

  return (
    pathMeta.show.footer && (
      <PortalFooter mode={pathMeta.backGroundMode} {...props} />
    )
  );
}

export default PortalFooterWithPathMeta;
