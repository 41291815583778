import Avatar from '@mui/material/Avatar';

export interface PortalAvatarUserProfileCardProps {
  title: string;
  name: string;
  avatarUrl?: string;
  email?: string | null;
  link?: {
    href: string;
    label: string;
  };
}

export function PortalAvatarUserProfileCard({
  name,
  avatarUrl,
  email,
  link,
  title,
}: PortalAvatarUserProfileCardProps) {
  return (
    <div className="px-6 pt-4">
      <div className="vs-body-xs-regular text-vs-base-content-variant leading-5">
        {title}
      </div>
      <div className=" flex items-center space-x-4 min-h-[80px]">
        <div className="flex-shrink-0">
          <Avatar className="h-[56px] w-[56px]" alt={name} src={avatarUrl} />
        </div>
        <div className="flex flex-col gap-1">
          <div className="vs-heading-xs-bold leading-7">
            <span className="">{name}</span>
          </div>
          {email && <div className="vs-body-xs-regular leading-5">{email}</div>}
          {link && (
            <div className="leading-3">
              <a
                className="text-vs-secondary vs-body-xs-regular underline"
                href={link?.href}
                target="_blank"
                rel="noreferrer"
              >
                {link?.label}
              </a>
            </div>
          )}
        </div>
      </div>
      <div className="border-vs-neut-300 border-b w-full mt-4"></div>
    </div>
  );
}

export default PortalAvatarUserProfileCard;
