import { useVsPortalCommonInitialize } from '@vs/hooks/hooks-portal-common-initialize';
import { PortalBackdrop } from '@vs/ui/portal/backdrop';
import { memo } from 'react';

import AppContent from './appContent';

function AppContentInit() {
  const { isInitializing } = useVsPortalCommonInitialize();

  if (isInitializing) {
    return <PortalBackdrop open type="app_init" />;
  }
  return <AppContent />;
}

export default memo(AppContentInit);
