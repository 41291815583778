import { BaseGlobalState, hideToast } from '@vs/rtk/slices/genericGlobal';
import { PortalToast } from '@vs/ui/portal/toast';
import { memo } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

const useStateSelector: TypedUseSelectorHook<{ general: BaseGlobalState }> =
  useSelector;

/**
 * Show global toast meesage according to redux state.
 * * Redux store need to include `generalStateSlice`
 *
 * @export
 * @returns {ReactElement}
 */
export const PortalAppToast = memo(() => {
  const dispatch = useDispatch();
  const isToast = useStateSelector(s => s.general.isToast);
  const toastSetting = useStateSelector(s => s.general.toastSetting);

  return (
    <PortalToast
      open={isToast}
      message={toastSetting.message}
      duration={toastSetting.duration}
      vertical={toastSetting.vertical}
      horizontal={toastSetting.horizontal}
      actionType={toastSetting.actionType}
      onClose={() => dispatch(hideToast())}
    />
  );
});

export default PortalAppToast;
