export type VsErrorDataMsgDict = {
  [key: string]: string | number | object;
};

export type VsErrorData = {
  code: string;
  message: VsErrorDataMsgDict | string;
  path: string;
  request_id: string;
  service: string;
  status_code: number;
  timestamp: string;
  errors?: unknown[];
};
export function isErrorDataOfVsApi(
  errorData: unknown
): errorData is VsErrorData {
  return (
    typeof errorData === 'object' &&
    errorData != null &&
    'code' in errorData &&
    typeof errorData.code === 'string' &&
    'message' in errorData &&
    typeof errorData.message === 'string' &&
    'path' in errorData &&
    typeof errorData.path === 'string' &&
    'request_id' in errorData &&
    typeof errorData.request_id === 'string' &&
    'service' in errorData &&
    typeof errorData.service === 'string' &&
    'status_code' in errorData &&
    typeof errorData.status_code === 'number' &&
    'timestamp' in errorData &&
    typeof errorData.timestamp === 'string'
  );
}

export function isErrorResponseOfVsApi(error: unknown): error is {
  status: number;
  data: VsErrorData;
} {
  return (
    typeof error === 'object' &&
    error != null &&
    'status' in error &&
    typeof error.status === 'number' &&
    'data' in error &&
    isErrorDataOfVsApi(error.data)
  );
}

export function isErrorResponseOfVsGrapQl(error: unknown): error is {
  message: string;
  name: string;
} {
  return (
    typeof error === 'object' &&
    error != null &&
    'message' in error &&
    typeof error.message === 'string' &&
    'name' in error &&
    typeof error.name === 'string'
  );
}

export type RtkErrorResponseOfVsApi = {
  status: 'VS_API_ERROR';
  originalStatus: number;
  data: VsErrorData;
};

export function transformErrorResponseOfVsApi(error: {
  status: number;
  data: VsErrorData;
}): RtkErrorResponseOfVsApi {
  return {
    status: 'VS_API_ERROR',
    originalStatus: error.status,
    data: error.data,
  };
}

export function isErrorOfVsApi(
  error: unknown
): error is RtkErrorResponseOfVsApi {
  return (
    typeof error === 'object' &&
    error != null &&
    'status' in error &&
    error.status === 'VS_API_ERROR'
  );
}

type ResClientError = {
  status: 400;
  data: {
    request_id: string;
    code: string;
    status_code: number;
    service: string;
    message: {
      [key in string]: string | number | object;
    };
    path: string;
    timestamp: string;
  };
};

export function isErrorOfClient(error: unknown): error is ResClientError {
  return (
    typeof error === 'object' &&
    error != null &&
    'status' in error &&
    error.status === 400
  );
}
