import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { env } from '../../../../constants/env';
import getUserAccessToken from '../../../../utils/getUserAccessToken';
import { GetAppLog, HistorySliceTags } from './security-api.type';

export const securityApiSlice = createApi({
  reducerPath: 'signInHistoryApi',
  keepUnusedDataFor: 60,
  // TODO: replace this with dynamicFetchBaseFactory when the Sign In History page released to handle access token
  baseQuery: fetchBaseQuery({
    baseUrl: env.apiEndpoint.mvb,
    prepareHeaders: async headers => {
      headers.set('x-version', '1');
      headers.set('authorization', `Bearer ${getUserAccessToken()}`);
    },
    credentials: 'include',
  }),
  tagTypes: Object.values(HistorySliceTags),
  endpoints: builder => ({
    getUserLog: builder.query<GetAppLog, { accountId: string }>({
      query: ({ accountId }) => ({
        url: `/api/account/log/login/report/${accountId}`,
      }),
      providesTags: [HistorySliceTags.WINDOWS_Log],
    }),
    getWindowsAppLog: builder.query<GetAppLog, { accountId: string }>({
      query: ({ accountId }) => ({
        url: `/viewboard/application/log/login/${accountId}/latest?type=windows&${Date.now()}`,
      }),
      providesTags: [HistorySliceTags.WINDOWS_Log],
    }),
    getAndroidAppLog: builder.query<GetAppLog, { accountId: string }>({
      query: ({ accountId }) => ({
        url: `/viewboard/application/log/login/${accountId}/latest?type=android&${Date.now()}`,
      }),
    }),
    getCompanionAppLog: builder.query<GetAppLog, { accountId: string }>({
      query: ({ accountId }) => ({
        url: `/viewboard/application/log/login/${accountId}/latest?${Date.now()}`,
      }),
    }),
  }),
});

export const {
  useGetUserLogQuery,
  useGetWindowsAppLogQuery,
  useGetAndroidAppLogQuery,
  useGetCompanionAppLogQuery,
} = securityApiSlice;
